
import MotionDiv from "../../components/UI/MotionDiv";
import { useTranslation, Trans } from "react-i18next";
import Button from "../../components/UI/Button";
import celebrateIcon from "../../assets/images/programme/icon_celebrate.svg";
import discussIcon from "../../assets/images/programme/icon_discuss.svg";
import endIcon from "../../assets/images/programme/icon_end.svg";
import pollIcon from "../../assets/images/programme/icon_poll.svg";
import regIcon from "../../assets/images/programme/icon_reg.svg";
import speechIcon from "../../assets/images/programme/icon_speech.svg";
import talkIcon from "../../assets/images/programme/icon_talk.svg";
import videoIcon from "../../assets/images/programme/icon_video.svg";
import speakerImg from "../../assets/images/programme/img_speaker.jpg";
import downWhiteArrow from "../../assets/images/share/arrow_down_white.svg";

import photo1 from "../../assets/images/info/photo/img_photo1.jpg";
import photo2 from "../../assets/images/info/photo/img_photo2.jpg";
import photo3 from "../../assets/images/info/photo/img_photo3.jpg";
import photo4 from "../../assets/images/info/photo/img_photo4.jpg";

import "./index.scss";
import React, {useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import { Frame, motion,  useAnimation, Override, Data, AnimatePresence} from "framer-motion";
import {
    LightgalleryProvider,
    LightgalleryItem,
    withLightgallery,
    useLightgallery
} from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";
import 'react-modal-video/scss/modal-video.scss';
import ModalVideo from "react-modal-video";
import useCheckDeviceMode from "../../components/hooks/useCheckDeviceMode";
import { LazyLoadImage } from "react-lazy-load-image-component";

function InformationCenter() {
    const { t, i18n } = useTranslation();
    const [currentLng, setCurrentLng] = useState(i18n.language);
    const history = useHistory();
    // const swiper = useSwiper();
    const [activeTab, setActiveTab] = useState("videos");
    const [showSessionList, setShowSessionList] = useState(false);
    const [speakerContent, setSpeakerContent] = useState();
    const responsiveMode = useCheckDeviceMode();

    const [isOpenReg, setIsOpenReg] = useState(false);

    useEffect(()=>{
        //months always start at 0 while days start at 1
        if (new Date() >= new Date(2022, 11, 6)){
            setIsOpenReg(true)
        }
    }, [])

    function handleLink(e) {
        history.push(e);
    }


    const [photoList, setPhotoList] = useState([]);
    const [videoList, setVideoList] = useState([]);
    const [presentationList, setPresentationList] = useState([]);
    const [pressList, setPressList] = useState([]);

    useEffect(()=>{
        // console.log(i18n.language)
    }, [i18n.language])
    useEffect(()=>{
        // console.log("currentLng", i18n.language)
        if (i18n.language) {
            const fetchInfoList = async () => {
                fetch("/assets/data/infoList_"+i18n.language+".json", {
                    headers : {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                })
                    .then(res => res.json())
                    .then((result) => {
                        setPhotoList(result.photos)
                        setVideoList(result.videos)
                        setPresentationList(result.presentations)
                        setPressList(result.press)

                    })
            };
            fetchInfoList();
        }

    }, [i18n.language])

    function VideoWrap (props){
        const [isOpen, setIsOpen] = useState(false);
        // console.log("id", props.video.id)
        // console.log("thumbnail", props.video.thumbnail)
        return(
            <div className={"videoWrap"}>
                <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={props.video.id} onClose={() => setIsOpen(false)} />
                <button className="btn-primary" onClick={()=> setIsOpen(true)}>
                    {/*<div>*/}
                        <img src={props.video.thumbnail} />
                        {/*<img src={"https://img.youtube.com/vi/"+props.video.id+"/0.jpg"} />*/}

                    {/*</div>*/}
                </button>
                <h2>{props.video.title}</h2>
                <div className={"videoDetails"} dangerouslySetInnerHTML={{ __html: props.video.subtitle }} />
            </div>
        )
    }

    const speakerContentVariants = {
        initial: { opacity: 0, y: 100},
        animate: {
            opacity: 1,
            y: 0,
        }
    };
    const programmeListVariants = {
        initial: { opacity: 0},
        animate: {
            opacity: 1,
        }
    };
    const programmeListVariants2 = {
        initial: { opacity: 0},
        animate: {
            opacity: 1,
        }
    };

    const sessionList = [
            {"cate": "photos", "name": t("informationCenter.photoGallery")},
            {"cate": "videos", "name": t("informationCenter.video")},
            {"cate": "presentations", "name": t("informationCenter.presentation")},
            {"cate": "press", "name": t("informationCenter.press")},
        ]

    return (
        <MotionDiv style={{display: 'flex'}}>
            <div className={"insidePageBg"}>
                {/*<div className="bgImg"></div>*/}
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>

                <div className={"insidePageContent"}>
                    <h1 className={"pageTitle"}>{t("informationCenter.title")}</h1>
                    <div className={"infoContent contentWrap"}>
                        {/*<iframe width="560" height="315" src="https://www.youtube.com/embed/dQw4w9WgXcQ"*/}
                        {/*        title="YouTube video player" frameBorder="0"*/}
                        {/*        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
                        {/*        allowFullScreen></iframe>*/}

                        <div className={"tabsWrap"}>

                            {responsiveMode === "desktop-view" &&
                                <div className={"sessionBtns"}>
                                    {/*{isOpenReg?*/}
                                    {/*    <>*/}
                                    <Button function={(e) => setActiveTab("videos")} className={`sessionBtn ${activeTab==="videos"? "active": ""}`}>
                                        {t("informationCenter.video")}
                                    </Button>
                                    <Button function={(e) => setActiveTab("photos")} className={`sessionBtn ${activeTab === "photos" ? "active" : ""}`}>
                                        {t("informationCenter.photoGallery")}
                                    </Button>
                                    <Button function={(e) => setActiveTab("presentations")} className={`sessionBtn ${activeTab==="presentations"? "active": ""}`}>
                                        {t("informationCenter.presentation")}
                                    </Button>
                                    {/*    </>*/}
                                        {/*:""*/}
                                    {/*}*/}
                                    <Button function={(e) => setActiveTab("press")} className={`sessionBtn ${activeTab==="press"? "active": ""}`}>
                                        {t("informationCenter.press")}
                                    </Button>
                                </div>
                            }
                            {responsiveMode!=="desktop-view"&&sessionList&&
                                <div className={"mobileSessionBtns"}>
                                    {sessionList.map((session, index) => {
                                        if (activeTab === session.cate) {
                                            return (
                                                <Button function={(e) => setShowSessionList(!showSessionList)} className={`toggleListBtn`} key={index}>
                                                {/*<Button function={(e) => setShowSessionList(!showSessionList)} className={`toggleListBtn ${showSessionList? "active": ""}`}>*/}
                                                    {session.name}
                                                </Button>
                                            )
                                        }
                                    })}

                                    <AnimatePresence>
                                        {showSessionList && (
                                            <motion.div
                                                layout
                                                initial={{ y: -40, opacity: 0 }}
                                                animate={{ y: 0, opacity: 1 }}
                                                exit={{ y: -40, opacity: 0 }}
                                                transition={{ duration: 0.2 }}

                                                className={"sessionDropdown"}>
                                                {sessionList.map((session, index) => {
                                                    if (activeTab !== session.cate) {
                                                        return (
                                                            <Button
                                                                function={(e) => {
                                                                    setActiveTab(session.cate)
                                                                    setShowSessionList(!showSessionList)
                                                                }}
                                                                key={index}
                                                                className={`sessionBtn ${activeTab === "photos" ? "active" : ""}`}
                                                            >
                                                                {session.name}
                                                            </Button>
                                                        )
                                                    }
                                                })}
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </div>
                            }
                        </div>

                        <AnimatePresence>

                            {activeTab==="photos" && (
                                <motion.div className={"galleryWrap"}
                                            initial={"initial"}
                                            animate={"animate"}
                                            exit={"initial"}
                                            variants={programmeListVariants}
                                >

                                    <LightgalleryProvider
                                        onBeforeOpen={() => console.info("onBeforeOpen")}
                                        onAfterOpen={() => console.info("onAfterOpen")}
                                        onSlideItemLoad={() => console.info("onSlideItemLoad")}
                                        onBeforeSlide={() => console.info("onBeforeSlide")}
                                        onAfterSlide={() => console.info("onAfterSlide")}
                                        onBeforePrevSlide={() => console.info("onBeforePrevSlide")}
                                        onBeforeNextSlide={() => console.info("onBeforeNextSlide")}
                                        onDragstart={() => console.info("onDragstart")}
                                        onDragmove={() => console.info("onDragmove")}
                                        onDragend={() => console.info("onDragend")}
                                        onSlideClick={() => console.info("onSlideClick")}
                                        onBeforeClose={() => console.info("onBeforeClose")}
                                        onCloseAfter={() => console.info("onCloseAfter")}
                                    >
                                        {photoList.map((image, index) => (
                                            <LightgalleryItem key={index} group={"group1"} src={image} thumb={image}>
                                                <LazyLoadImage src={image}
                                                               style={{ width: "100%" }}
                                                               visibleByDefault={index<12? true: false}
                                                />
                                                {/*<img src={image} style={{ width: "100%" }} />*/}
                                            </LightgalleryItem>
                                        ))}
                                    </LightgalleryProvider>
                                </motion.div>
                            )}
                        </AnimatePresence>
                        <AnimatePresence>
                            {activeTab==="videos" && (
                                <motion.div className={"videosWrap"}
                                            initial={"initial"}
                                            animate={"animate"}
                                            exit={"initial"}
                                            variants={programmeListVariants}
                                >

                                    {videoList.length>0&&videoList.map((video, index) => (
                                        <VideoWrap video={video} key={index} />
                                    ))}

                                </motion.div>
                            )}
                        </AnimatePresence>
                        <AnimatePresence>
                            {activeTab==="presentations" && (
                                <motion.div className={"presentationsWrap"}
                                            initial={"initial"}
                                            animate={"animate"}
                                            exit={"initial"}
                                            variants={programmeListVariants}
                                >

                                    {presentationList.length>0&&presentationList.map((presentation, index) => (
                                        <a className={"presentationWrap"} key={index} href={presentation.file} target={"_blank"}>
                                            <h2>{presentation.title}</h2>
                                            <div className={"videoDetails"} dangerouslySetInnerHTML={{ __html: presentation.speaker}} />
                                        </a>
                                    ))}

                                </motion.div>
                            )}
                        </AnimatePresence>
                        <AnimatePresence>
                            {activeTab==="press" && (
                                <motion.div className={"pressOuterWrap"}
                                            initial={"initial"}
                                            animate={"animate"}
                                            exit={"initial"}
                                            variants={programmeListVariants}
                                >

                                    {pressList.length>0&&pressList.map((press, index) => (
                                        <div className={"pressWrap"} key={index} >
                                            <span>{press.date}</span>
                                            <p>{press.title}</p>
                                            <a href={press.file} target={"_blank"}>{t("informationCenter.readMore")}</a>
                                        </div>
                                    ))}

                                </motion.div>
                            )}
                        </AnimatePresence>

                    </div>

                </div>
            </div>
        </MotionDiv>
    );
}

export default InformationCenter;
