const initState = {
    walkerX:{}
};
const walkerXReducer = (state = initState, action) => {

    switch(action.type) {
        case 'ADD_WALKER_X': {
            const { floorId, xCoord } = action.payload
            return {
                ...state,
                walkerX: {
                    ...state.walkerX,
                    [floorId]: xCoord
                }
            }
        }
        default:
            return state;

    }
};

export {walkerXReducer};