const initState = {
    walkingPathList:{}
};
const walkingPathReducer = (state = initState, action) => {

	switch(action.type) {
		case 'ADD_WALKINGPATH': {
			const { floorId, path } = action.payload
            return {
                ...state,
                walkingPathList: {
                    ...state.walkingPathList,
                    [floorId]: path
                }
            }
		}
		case 'RESET_WALKINGPATH': {
			return {
				// state = {
					...state,
                    walkingPathList: {}
            }
			// }
		}
        default: 
            return state;

    }
};

export {walkingPathReducer};