import MotionDiv from "../../components/UI/MotionDiv";

import React, {useEffect, useRef, useState} from "react";
function Loading() {

    return (
        <MotionDiv>
            <div className={"insidePageBg"}>
                <div className={"insidePageContent"}>
                    <h1 className={""}>Loading</h1>
                </div>
            </div>
        </MotionDiv>
    );
}

export default Loading;
