const facilityListByFloorReducer = (
    state = {
      facilityListByFloor: {
        "L1": {facility_group: []},
        "L2": {facility_group: []},
        "L3": {facility_group: []},
        "L4": {facility_group: []},
        "L5": {facility_group: []},
        "B1": {facility_group: []},
        "B2": {facility_group: []},
        "B4": {facility_group: []},
        "B5": {facility_group: []}
      },
                                                                                                 
    },
    action
  ) => {
    switch (action.type) {
      // case 'ADD_FLOOR': {
      //   const { floorId} = action.payload
      //   // return {
      //     state = {
      //       ...state,
      //       facilityListByFloor: {
      //         ...state.facilityListByFloor,
      //         [floorId]: {}
      //       }
      //     }
      //   // }
      // }
    
      case 'ADD_FACILITY_GROUP': {
        const { floorId, groupId, obj } = action.payload
        // return {
          state = {
            ...state,
            facilityListByFloor: {
              ...state.facilityListByFloor,
              [floorId]: {
                ...state.facilityListByFloor[floorId],
                facility_group: [
                  ...state.facilityListByFloor[floorId].facility_group,
                  obj
                ]
                // facility_group: {
                //   ...state.facilityListByFloor[floorId].facility_group,
                //   [groupId]: obj
                // }
              }
            }
          }
        // }
      }
      // case 'ADD_FACILITY_GROUP': {
      //   const { floorId, groupId, x, y, cate_list, facilityList } = action.payload
      //   // return {
      //     state = {
      //       ...state,
      //       facilityListByFloor: {
      //         ...state.facilityListByFloor,
      //         [floorId]: {
      //           ...state.facilityListByFloor[floorId],
      //           facility_group: [
      //             ...state.facilityListByFloor[floorId].facility_group,
      //             {
      //               x: x,
      //               y: y,
      //               cate_list: cate_list, 
      //               facilityList: facilityList
      //             }
      //           ]
      //         }
      //       }
      //     }
      //   // }
      // }

      // case 'ADD_FACILITY': {
      //   const { floorId, facilityId, x, y, cate } = action.payload
      //   // return {
      //     state = {
      //       ...state,
      //       facilityListByFloor: {
      //         ...state.facilityListByFloor,
      //         [floorId]: {
      //           ...state.facilityListByFloor[floorId],
      //           facilities: {
      //             ...state.facilityListByFloor[floorId].facilities,
      //             [facilityId]: {
      //               x: x,
      //               y: y,
      //               cate: cate
      //             }
      //           }
      //         }
      //       }
      //     }
      //   // }
      // }

      default:
        return state;
    }
  };
  
  export { facilityListByFloorReducer };
  