import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import Button from "../../../UI/Button";
import useCheckDeviceMode from "../../../hooks/useCheckDeviceMode";

const Languageswitcher = () => {
    const { i18n } = useTranslation();
    const location = useLocation();
    const langList = ["tc", "en", "sc"];
    const responsiveMode = useCheckDeviceMode();

    const [language, setLanguage] = useState(i18n.language);

    const handleClick = (nextLanguage) => {
        const routes = i18n.getResourceBundle(i18n.language, 'routes');
        const currentPathname = window.location.pathname.replace(/\/+$/, '');
        const newPathName = currentPathname.replace(i18n.language, nextLanguage)

        localStorage.setItem("i18nextLng", nextLanguage);

        i18n.changeLanguage(nextLanguage);
        document.documentElement.lang = i18n.language
        window.history.replaceState(null, "", window.location.origin+newPathName)

        // window.location.reload();

    };

    return (
        <>
            <div className={"languageswitcher-wrap" + (location.pathname !== "/" ? " hidden" : "") }>
                <ul>
                    {langList.map(
                        (locale) =>
                            locale !== i18n.language && (
                                <li key={locale}>
                                    <Button function={(e) => handleClick(e.target.value)} value={locale} >
                                        {locale === "en"
                                            ? "EN"
                                            : locale === "tc"
                                                ? "繁"
                                                : "简"}
                                    </Button>
                                </li>
                            )
                    )}
                </ul>
            </div>
        </>
    );
}

export default Languageswitcher;