import { useSelector } from "react-redux";

function Button(props) {
  // Touch device check, switch between onTouchEnd and onClick
  // const isTouch = useSelector((state) => state.layoutReducer.device.isTouch);
  const isTouch = true;

  return (
    <>
      {/*{isTouch ? (*/}
      {/*  <button key={(props.key ? props.key : undefined)} value={(props.value ? props.value : undefined)} className={'handle-touch ' + (props.className ? props.className: '')} onTouchEnd={(props.function ? props.function : null)}>{props.children}</button>*/}
      {/*) : (*/}
        <button value={(props.value ? props.value : undefined)} className={'handle-click  ' + (props.className ? props.className: '')} onClick={(props.function ? props.function : null)}>{props.children} </button>
      {/*)}*/}
    </>
  );
}

export default Button;
