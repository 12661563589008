const initState = {
    cyRef:{}
};
const cyRefReducer = (state = initState, action) => {

	switch(action.type) {
		case 'ADD_CYREF': {
			const { floorId, object } = action.payload
            state = {
                ...state,
                cyRef: {
                    ...state.cyRef,
                    [floorId]: object
                }
            }
		}
        default: 
            return state;

    }
};

export {cyRefReducer};