const layoutReducer = (
  state = { router: { prevPathname: "/" }, device: { isTouch: true } },
  action
) => {
  switch (action.type) {
    case "CREATE_PREV_LOCATION": {
      const pathname = action.payload;
      return {
        ...state,
        router : {
          prevPathname: pathname
        }
      };
    }
    case "CREATE_DEVICE_ISTOUCH_CHECK": {
      const checkResult = action.payload;
      return {
        ...state,
        device : {
          isTouch: checkResult
        }
      };
    }
    default:
      return state;
  }
  
};

export { layoutReducer };
