import {Trans, useTranslation} from "react-i18next";
import React, {useState} from "react";
import {AnimatePresence, motion, useScroll} from "framer-motion";
import useCheckDeviceMode from "../../hooks/useCheckDeviceMode";
import Button from "../Button";
import coorImg from "../../../assets/images/programme/img_coor.png";

function ProgrammeWrap (props) {
    const { t } = useTranslation();
    const [detailsOpened, setDetailsOpened] = useState(false);
    const responsiveMode = useCheckDeviceMode();

    function toggleDetails(){
        setDetailsOpened(!detailsOpened);

    }
    return(
        <div className={`programmeWrap ${props.type}`}>
            <div className={"programmeContent"}>
                <div className={"iconWrap"}>
                    <img src={props.icon}/>
                </div>

                <div className={"programmeDetails"}>
                    <span>{props.time}</span>
                    <h3>{props.title}</h3>
                    {props.subtitle?<h4>{props.subtitle}</h4>:""}
                    {responsiveMode !== "desktop-view" && props.details?
                        <>
                            <Button className={`toggleDetailsBtn ${detailsOpened ? "opened":""}`} function={toggleDetails} />

                        </>
                        :""
                    }

                    <AnimatePresence>
                        {responsiveMode==="desktop-view"&&props.details ||responsiveMode!=="desktop-view"&&props.details && detailsOpened?
                            <motion.div  className={"speakerDetailsOuterWrap"}
                                // initial={"initial"}
                                // animate={"animate"}
                                // exit={"initial"}
                                // variants={menuVariants}>
                                         initial={{opacity: 0, y: -20}}
                                         animate={{opacity: 1, y: 0}}
                                         exit={{opacity: 0, y: -20}}>
                                {props.coor ?
                                    <div className={"coorWrap"}>
                                        <span>{t("programme.coorganised")}</span>
                                        <div>
                                            <img src={coorImg}/>
                                        </div>
                                    </div>
                                    : ""
                                }
                                <div className={"speakerDetailsWrap"}>
                                    {props.mod1Name?
                                        <div className={"speakerDetails"}>
                                            <span>{t("programme.moderator")}</span>
                                            <div className={"speakerList"}>
                                                <div className={"speakerWrap"}>
                                                    {/*<img src={props.mod1Img}/>*/}
                                                    <div>
                                                        <Button
                                                            function={props.mod1Content&&props.mod1Img!=="/assets/images/speakers/placeholder.png"&&props.mod1Content!=="n/a" ? (e) => {
                                                                props.clickSpeaker(e, props.mod1Img, props.mod1PopupName, props.mod1PopupTitle, props.mod1Content)}:""
                                                            }
                                                            className={props.mod1Content&&props.mod1Img!=="/assets/images/speakers/placeholder.png"&&props.mod1Content!=="n/a" ?"":"disabled"}
                                                        >
                                                            {/*{props.mod1Name}*/}
                                                            <div dangerouslySetInnerHTML={{__html: props.mod1Name,}}/>
                                                        </Button>
                                                        <Trans components={[<br/>]} i18nKey={props.mod1Title} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>:""
                                    }
                                    {props.speaker1Name?
                                        <div className={"speakerDetails"}>
                                            <span>{props.speaker1Name&&props.speaker2Name&props.time!=="5:00pm"?t("programme.panelists"): props.speaker1Name&&props.speaker2Name?t("programme.speakers"):t("programme.speaker")}</span>
                                            <div className={"speakerList"}>
                                                <div className={"speakerWrap"}>
                                                    {/*<img src={props.speaker1Img}/>*/}
                                                    <div>
                                                        <Button
                                                            function={props.speaker1Content&&props.speaker1Img!=="/assets/images/speakers/placeholder.png"&&props.speaker1Content!=="n/a" ? (e) => {
                                                                props.clickSpeaker(e, props.speaker1Img, props.speaker1PopupName, props.speaker1PopupTitle, props.speaker1Content)}:""
                                                            }
                                                            className={props.speaker1Content&&props.speaker1Img!=="/assets/images/speakers/placeholder.png"&&props.speaker1Content!=="n/a" ?"":"disabled"}
                                                        >
                                                            <div dangerouslySetInnerHTML={{__html: props.speaker1Name,}}/></Button>
                                                        <Trans components={[<br/>]} i18nKey={props.speaker1Title}/>
                                                    </div>
                                                </div>
                                                {props.speaker2Name?
                                                    <div className={"speakerWrap"}>
                                                        {/*<img src={props.speaker2Img}/>*/}
                                                        <div>
                                                            <Button
                                                                function={props.speaker2Content&&props.speaker2Img!=="/assets/images/speakers/placeholder.png"&&props.speaker2Content!=="n/a" ? (e) => {
                                                                    props.clickSpeaker(e, props.speaker2Img, props.speaker2PopupName, props.speaker2PopupTitle, props.speaker2Content)}:""
                                                                }
                                                                className={props.speaker2Content&&props.speaker2Img!=="/assets/images/speakers/placeholder.png"&&props.speaker2Content!=="n/a" ?"":"disabled"}
                                                            >
                                                                <div dangerouslySetInnerHTML={{__html: props.speaker2Name,}}/></Button>
                                                            <Trans components={[<br/>]} i18nKey={props.speaker2Title} />
                                                        </div>
                                                    </div>
                                                    :""
                                                }
                                                {props.speaker3Name?
                                                    <div className={"speakerWrap"}>
                                                        {/*<img src={props.speaker3Img}/>*/}
                                                        <div>
                                                            <Button
                                                                function={props.speaker3Content&&props.speaker3Img!=="/assets/images/speakers/placeholder.png"&&props.speaker3Content!=="n/a" ? (e) => {
                                                                    props.clickSpeaker(e, props.speaker3Img, props.speaker3PopupName, props.speaker3PopupTitle, props.speaker3Content)}
                                                                    :""}
                                                                className={props.speaker3Content&&props.speaker3Img!=="/assets/images/speakers/placeholder.png"&&props.speaker3Content!=="n/a" ?"":"disabled"}
                                                            >
                                                            <div dangerouslySetInnerHTML={{__html: props.speaker3Name,}}/></Button>
                                                            <Trans i18nKey={props.speaker3Title} components={[<br />]} />
                                                        </div>
                                                    </div>
                                                    :""
                                                }
                                                {props.speaker4Name?
                                                    <div className={"speakerWrap"}>
                                                        {/*<img src={props.speaker4Img}/>*/}
                                                        <div>
                                                            <Button
                                                                function={props.speaker4Content&&props.speaker4Img!=="/assets/images/speakers/placeholder.png"&&props.speaker4Content!=="n/a" ? (e) => {
                                                                    props.clickSpeaker(e, props.speaker4Img, props.speaker4PopupName, props.speaker4PopupTitle, props.speaker4Content)}:""
                                                                }
                                                                className={props.speaker4Content&&props.speaker4Img!=="/assets/images/speakers/placeholder.png"&&props.speaker4Content!=="n/a" ?"":"disabled"}><div dangerouslySetInnerHTML={{__html: props.speaker4Name,}}/></Button>
                                                            <Trans i18nKey={props.speaker4Title} components={[<br />]} />
                                                        </div>
                                                    </div>
                                                    :""
                                                }
                                                {props.speaker5Name?
                                                    <div className={"speakerWrap"}>
                                                        {/*<img src={props.speaker5Img}/>*/}
                                                        <div>
                                                            <Button
                                                                function={props.speaker5Content&&props.speaker5Img!=="/assets/images/speakers/placeholder.png"&&props.speaker5Content!=="n/a" ? (e) => {
                                                                    props.clickSpeaker(e, props.speaker5Img, props.speaker5PopupName, props.speaker5PopupTitle, props.speaker5Content)}:""
                                                                }
                                                                className={props.speaker5Content&&props.speaker5Img!=="/assets/images/speakers/placeholder.png"&&props.speaker5Content!=="n/a" ?"":"disabled"}><div dangerouslySetInnerHTML={{__html: props.speaker5Name,}}/></Button>
                                                            <Trans i18nKey={props.speaker5Title} components={[<br />]} />
                                                        </div>
                                                    </div>
                                                    :""
                                                }
                                            </div>
                                        </div>
                                        : ""
                                    }

                                </div>


                            </motion.div>
                            :""
                        }
                    </AnimatePresence>

                </div>
            </div>
            {/*{props.video || props.speech || props.poll ?*/}
            {/*    <div className={"programmeBanner"}>*/}
            {/*        {props.video ? <a className={"video"} href={props.video}>{t("programme.video")}</a> : ""}*/}
            {/*        {props.speech ? <a className={"speech"} href={props.speech}>{t("programme.speech")}</a> : ""}*/}
            {/*        {props.poll ? <a className={"poll"} href={props.poll}>{t("programme.pollingResult")}</a> : ""}*/}
            {/*    </div>*/}
            {/*    : ""*/}
            {/*}*/}
        </div>
    )
};

export default ProgrammeWrap;