import { useState, useEffect } from "react";

export default function useCheckDeviceMode() {
    const [mode, setMode] = useState();

    useEffect(() => {
        // only execute all the code below in client side
        if (typeof window !== "undefined") {
            // Handler to call on window resize
            function handleResize() {
                let currentView;
                if (window.innerWidth > 1180) {
                    currentView = "desktop-view";
                } else if ((window.innerWidth < 1180) & (window.innerWidth > 767)) {
                    currentView = "tablet-view";
                } else {
                    currentView = "mobile-view";
                }
                setMode(currentView);
            }

            // Add event listener
            window.addEventListener("resize", handleResize);

            // Call handler right away so state gets updated with initial window size
            handleResize();

            // Remove event listener on cleanup
            return () => window.removeEventListener("resize", handleResize);
        }
    }, []); // Empty array ensures that effect is only run on mount
    return mode;
}
