
import { motion } from "framer-motion";

function MotionDiv(props) {
    return(
        <motion.div
            className={props.className}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: "easeInOut", duration:0.3 }}
            data-scroll-section
        >
            {props.children}
        </motion.div>
    )
}

export default MotionDiv;