const settingReducer = (
    state = { apiInfo: {} },
    action
  ) => {
    switch (action.type) {
      case "CREATE_SETTING_API_INFO": {
        const apiInfo = action.payload;
        return {
          ...state,
          apiInfo : apiInfo
        };
      }
     
      default:
        return state;
    }
    
  };
  
  export { settingReducer };
  