const initState = {
    lineXYStrList:{}
};
const lineXYStrReducer = (state = initState, action) => {

	switch(action.type) {
		case 'ADD_XY': {
			const { floorId, str } = action.payload
            return {
                ...state,
                lineXYStrList: {
                    ...state.lineXYStrList,
                    [floorId]: str
                }
            }
		}
		case 'RESET_XY': {
			return {
				// state = {
					...state,
                    lineXYStrList: {}
            }
			// }
		}
        default: 
            return state;

    }
};

export {lineXYStrReducer};