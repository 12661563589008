
import { motion } from "framer-motion";

function FadeInOnScrollDiv(props) {

    const variants = {
        offscreen: {
            opacity: 0
        },
        onscreen: {
            opacity: 1
        }
    };

    return(

        <motion.div
            className={props.className}
            initial="offscreen"
            whileInView="onscreen"
            variants={variants}
            onViewportLeave
            viewport={{
                once: true,
                amount: 0.2,
                margin: "0px"
            }}
        >
            {props.children}
        </motion.div>
    )
}

export default FadeInOnScrollDiv;