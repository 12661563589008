
import MotionDiv from "../../components/UI/MotionDiv";
import Parallax from "../../components/UI/Parallax";
import { useTranslation, Trans } from "react-i18next";
import Button from "../../components/UI/Button";
import speaker1Img from "../../assets/images/speakers/img_speaker1.png";
import speaker2Img from "../../assets/images/speakers/img_speaker2.png";
import speaker3Img from "../../assets/images/speakers/img_speaker3.png";
import downWhiteArrow from "../../assets/images/share/arrow_down_white.svg";
import openBtn from "../../assets/images/share/btn_open.svg";

import "./index.scss";
import React, {useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import { Frame, motion,  useAnimation, Override, Data, AnimatePresence} from "framer-motion";
import useCheckDeviceMode from "../../components/hooks/useCheckDeviceMode";
import SpeakerPopup from "../../components/UI/SpeakerPopup";

function Programme() {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [currentLng, setCurrentLng] = useState(i18n.language);
    // const swiper = useSwiper();
    const [morningActive, setMorningActive] = useState(true);
    const [speakerImg, setSpeakerImg] = useState();
    const [speakerName, setSpeakerName] = useState();
    const [speakerHonor, setSpeakerHonor] = useState();
    const [speakerTitle, setSpeakerTitle] = useState();
    const [speakerDepartment, setSpeakerDepartment] = useState();
    const [speakerContent, setSpeakerContent] = useState();
    const [speakerList, setSpeakerList] = useState([]);
    const responsiveMode = useCheckDeviceMode();


    useEffect(()=>{
        setCurrentLng(i18n.language)
    }, [i18n.language])


    function handleLink(e) {
        history.push(e);
    }


    const programmeListVariants = {
        initial: { opacity: 0},
        animate: {
            opacity: 1,
        }
    };
    const programmeListVariants2 = {
        initial: { opacity: 0},
        animate: {
            opacity: 1,
        }
    };

    useEffect(()=>{
        if (currentLng){
            // console.log(currentLng)

            const fetchSpeakers = async () => {
                fetch("/assets/data/speakers_"+currentLng+".json", {
                    headers : {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                })
                    .then(res => res.json())
                    .then((result) => {
                        // console.log(result.speakers)
                        const inputArray = Object.values(result.speakers)
                        // console.log("inputArray", inputArray)
                        const filteredArray = inputArray.filter(speakers => speakers["img"]!=="/assets/images/speakers/placeholder.png"&&speakers["content"]!=="n/a");
                        setSpeakerList(filteredArray)

                    })
            };
            fetchSpeakers();

        }
    }, [currentLng])

    useEffect(()=>{

        if (speakerList&&speakerList.length>0){
            // console.log("speakerList", speakerList)


            if (window.location.hash){
                let speaker = speakerList.filter(speaker => {
                    return window.location.hash.substring(1)===speaker.id
                })
                // console.log("speaker", speaker)

                if (speaker[0]&&speaker[0].id){
                    if(document.getElementById(speaker[0].id)){

                        let location = document.getElementById(speaker[0].id);
                        // console.log(location.offsetTop)

                        window.scrollTo({
                            top:location.offsetTop-148,
                            behavior:"smooth"
                        });

                    }

                    if (speaker[0].content){
                        setTimeout(()=>{
                            setSpeakerImg(speaker[0].img)
                            setSpeakerName(speaker[0].name)
                            setSpeakerTitle(speaker[0].title)
                            setSpeakerContent(speaker[0].content)
                        }, 1000);

                    }

                }

            }
        }
    }, [speakerList])


    useEffect(()=>{
        if (t){
            setCurrentLng(i18n.language)
            hideSpeakerContent()
        }
    }, [t])

    const hideSpeakerContent = () => {
        setSpeakerContent(undefined)
    }



    const SpeakerItem = ({speaker}) =>{
        const myRef = useRef(null)
        const executeScroll = () => myRef.current.scrollIntoView()

        const clickSpeaker = (e, img, name, title, content) => {
            // console.log(e)
            executeScroll()

            setTimeout(()=>{
                setSpeakerImg(img)
                setSpeakerName(name)
                setSpeakerTitle(title)
                setSpeakerContent(content)
            }, 200);
        }

        // useEffect(()=>{
        //     if (index===0){
        //         setIsOpen(true)
        //     }
        // }, [index])

        return(
            <div className={"speakerWrap"} ref={myRef} id={speaker.id}>
                <div className={"speakerDetails"}>

                    <div dangerouslySetInnerHTML={{__html: speaker.name,}}/>
                    <div dangerouslySetInnerHTML={{__html: speaker.title,}}/>

                    {/*<div>*/}
                    {/*    <h3>{speaker.name}</h3>*/}
                    {/*    <span>{speaker.honor}</span>*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*    <b>{speaker.title}</b>*/}
                    {/*    <p>{speaker.department}</p>*/}
                    {/*</div>*/}


                </div>
                <div className={"imgOuterWrap"}>
                    <div className={"imgInnerWrap"}>
                        <img src={speaker.img} />
                    </div>
                    {speaker.content ?
                        <Button className={"btnWithHover"} function={(e) => {
                            clickSpeaker(e, speaker.img, speaker.name, speaker.title, speaker.content)
                        }}>

                            <img src={openBtn} />
                        </Button> :""

                    }
                </div>
            </div>
        )
    }

    return (
        <MotionDiv>
            <div className={"insidePageBg"}>
                {/*<div className="bgImg"></div>*/}
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>

                <div className={"insidePageContent"}>
                    <h1 className={"pageTitle"}>{t("speakers.title")}</h1>
                    <div className={"contentWrap speakersContentWrap"}>
                        <p className={"noletterSpace"}>{t("speakers.ps")}</p>


                        {speakerList&&speakerList.length>0&&speakerList.map((speaker, i)=>{
                            return (
                                <SpeakerItem speaker={speaker} key={i} />
                            )
                        })}

                        {/*<div className={"speakerWrap"}>*/}
                        {/*    <div className={"speakerDetails"}>*/}
                        {/*        <h3>{t("speakerList.speaker1.name")}</h2>*/}
                        {/*        <span>{t("speakerList.speaker1.honor")}</span>*/}
                        {/*        <b>{t("speakerList.speaker1.title")}</b>*/}
                        {/*        <p>{t("speakerList.speaker1.department")}</p>*/}
                        {/*    </div>*/}
                        {/*    <div className={"imgOuterWrap"}>*/}
                        {/*        <div className={"imgInnerWrap"}>*/}
                        {/*            <img src={speaker1Img} />*/}
                        {/*        </div>*/}
                        {/*        <Button function={(e) => clickSpeaker(e, t("speakerList.speaker1.content"))}>*/}
                        {/*            <img src={openBtn} />*/}
                        {/*        </Button>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className={"speakerWrap"}>*/}
                        {/*    <div className={"speakerDetails"}>*/}
                        {/*        <h2>{t("speakerList.speaker2.name")}</h2>*/}
                        {/*        <span>{t("speakerList.speaker2.honor")}</span>*/}
                        {/*        <b>{t("speakerList.speaker2.title")}</b>*/}
                        {/*        <p>{t("speakerList.speaker2.department")}</p>*/}
                        {/*    </div>*/}
                        {/*    <div className={"imgOuterWrap"}>*/}
                        {/*        <div className={"imgInnerWrap"}>*/}
                        {/*            <img src={speaker2Img} />*/}
                        {/*        </div>*/}
                        {/*        <Button function={(e) => clickSpeaker(e, t("speakerList.speaker2.content"))}>*/}
                        {/*            <img src={openBtn} />*/}
                        {/*        </Button>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className={"speakerWrap"}>*/}
                        {/*    <div className={"speakerDetails"}>*/}
                        {/*        <h2>{t("speakerList.speaker3.name")}</h2>*/}
                        {/*        <b>{t("speakerList.speaker3.title")}</b>*/}
                        {/*        <p>{t("speakerList.speaker3.department")}</p>*/}
                        {/*    </div>*/}
                        {/*    <div className={"imgOuterWrap"}>*/}
                        {/*        <div className={"imgInnerWrap"}>*/}
                        {/*            <img src={speaker3Img} />*/}
                        {/*        </div>*/}
                        {/*        <Button function={(e) => clickSpeaker(e, t("speakerList.speaker3.content"))}>*/}
                        {/*            <img src={openBtn} />*/}
                        {/*        </Button>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className={"speakerWrap"}>*/}
                        {/*    <div className={"speakerDetails"}>*/}
                        {/*        <h2>{t("speakerList.speaker4.name")}</h2>*/}
                        {/*        <span>{t("speakerList.speaker4.honor")}</span>*/}
                        {/*        <b>{t("speakerList.speaker4.title")}</b>*/}
                        {/*        <p>{t("speakerList.speaker4.department")}</p>*/}
                        {/*    </div>*/}
                        {/*    <div className={"imgOuterWrap"}>*/}
                        {/*        <div className={"imgInnerWrap"}>*/}
                        {/*            <img src={speaker1Img} />*/}
                        {/*        </div>*/}
                        {/*        <Button function={(e) => clickSpeaker(e, t("speakerList.speaker4.content"))}>*/}
                        {/*            <img src={openBtn} />*/}
                        {/*        </Button>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className={"speakerWrap"}>*/}
                        {/*    <div className={"speakerDetails"}>*/}
                        {/*        <h2>{t("speakerList.speaker5.name")}</h2>*/}
                        {/*        <b>{t("speakerList.speaker5.title")}</b>*/}
                        {/*        <p>{t("speakerList.speaker5.department")}</p>*/}
                        {/*    </div>*/}
                        {/*    <div className={"imgOuterWrap"}>*/}
                        {/*        <div className={"imgInnerWrap"}>*/}
                        {/*            <img src={speaker2Img} />*/}
                        {/*        </div>*/}
                        {/*        <Button function={(e) => clickSpeaker(e, t("speakerList.speaker5.content"))}>*/}
                        {/*            <img src={openBtn} />*/}
                        {/*        </Button>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className={"speakerWrap"}>*/}
                        {/*    <div className={"speakerDetails"}>*/}
                        {/*        <h2>{t("speakerList.speaker6.name")}</h2>*/}
                        {/*        <b>{t("speakerList.speaker6.title")}</b>*/}
                        {/*        <p>{t("speakerList.speaker6.department")}</p>*/}
                        {/*    </div>*/}
                        {/*    <div className={"imgOuterWrap"}>*/}
                        {/*        <div className={"imgInnerWrap"}>*/}
                        {/*            <img src={speaker3Img} />*/}
                        {/*        </div>*/}
                        {/*        <Button function={(e) => clickSpeaker(e, t("speakerList.speaker6.content"))}>*/}
                        {/*            <img src={openBtn} />*/}
                        {/*        </Button>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className={"speakerWrap"}>*/}
                        {/*    <div className={"speakerDetails"}>*/}
                        {/*        <h2>{t("speakerList.speaker7.name")}</h2>*/}
                        {/*        <b>{t("speakerList.speaker7.title")}</b>*/}
                        {/*        <p>{t("speakerList.speaker7.department")}</p>*/}
                        {/*        <b>{t("speakerList.speaker7.title2")}</b>*/}
                        {/*        <p>{t("speakerList.speaker7.department2")}</p>*/}
                        {/*    </div>*/}
                        {/*    <div className={"imgOuterWrap"}>*/}
                        {/*        <div className={"imgInnerWrap"}>*/}
                        {/*            <img src={speaker1Img} />*/}
                        {/*        </div>*/}
                        {/*        <Button function={(e) => clickSpeaker(e, t("speakerList.speaker7.content"))}>*/}
                        {/*            <img src={openBtn} />*/}
                        {/*        </Button>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>

                    <AnimatePresence mode={"wait"}>>
                        {speakerImg && speakerName && speakerTitle && speakerContent ?
                            <SpeakerPopup speakerImg={speakerImg} speakerName={speakerName} speakerTitle={speakerTitle} speakerContent={speakerContent} setSpeakerContent={setSpeakerContent} hideSpeakerContent={hideSpeakerContent}/>
                            :""
                        }
                    </AnimatePresence>

                </div>
            </div>
        </MotionDiv>
    );
}

export default Programme;
