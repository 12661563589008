
import MotionDiv from "../../components/UI/MotionDiv";
import { useTranslation, Trans } from "react-i18next";
import Button from "../../components/UI/Button";
import celebrateIcon from "../../assets/images/programme/icon_celebrate.svg";
import discussIcon from "../../assets/images/programme/icon_discuss.svg";
import endIcon from "../../assets/images/programme/icon_end.svg";
import pollIcon from "../../assets/images/programme/icon_poll.svg";
import regIcon from "../../assets/images/programme/icon_reg.svg";
import speechIcon from "../../assets/images/programme/icon_speech.svg";
import talkIcon from "../../assets/images/programme/icon_talk.svg";
import videoIcon from "../../assets/images/programme/icon_video.svg";
import speakerImg from "../../assets/images/programme/img_speaker.jpg";
import downWhiteArrow from "../../assets/images/share/arrow_down_white.svg";

import "./index.scss";
import React, {useEffect, useRef, useState} from "react";
import {useHistory, Link} from "react-router-dom";
import {Frame, motion, useAnimation, AnimateSharedLayout, AnimatePresence, LayoutGroup} from "framer-motion";

function Registration() {
    const { t, i18n } = useTranslation();
    const baseUrl = '/'+i18n.language;
    const history = useHistory();
    const [currentLng, setCurrentLng] = useState(i18n.language);
    // const swiper = useSwiper();
    const [morningActive, setMorningActive] = useState(true);
    const [speakerContent, setSpeakerContent] = useState();
    const [isOpenReg, setIsOpenReg] = useState(false);
    const [isLive, setIsLive] = useState(false);
    const [faqContent1, setFaqContent1] = useState(t("registration.faqContent1_before"));


    useEffect(()=>{
        let liveTime = new Date("2023-12-08T08:30:00.000+08:00");
        let finishTime = new Date("2023-12-08T17:05:00.000+08:00")

        if (new Date() >= liveTime){
            setIsLive(true)
        }else{
            let timeDiff = liveTime.getTime() - Date.now()
            function timeoutFunc(){
                setIsLive(true)
            }
            setTimeout(timeoutFunc, timeDiff);
        }

    }, [])

    useEffect(() => {
        if(isLive){
            setFaqContent1(t("registration.faqContent1_after"))
        }else{
            setFaqContent1(t("registration.faqContent1_before"))

        }
    }, [isLive, currentLng]);
    useEffect(()=>{
        setCurrentLng(i18n.language)
    }, [i18n.language])

    const faqList = [
        [t("registration.faqQ1"), faqContent1, [<a href={`https://aif2023-reg.apac-event.com/Virtual` + baseUrl} target={"_blank"}/>, <br/>, <a href={"mailto:aif@ia.org.hk"} />]],
        [t("registration.faqQ2"), t("registration.faqContent2"), [<a href={`https://aif2023-reg.apac-event.com/Virtual` + baseUrl}  target={"_blank"}/>, <br/>, <a href={"mailto:aif@ia.org.hk"} />]],
        [t("registration.faqQ3"), t("registration.faqContent3"), [<a href={`https://aif2023-reg.apac-event.com/Virtual` + baseUrl}  target={"_blank"}/>, <br/>, <a href={"mailto:aif@ia.org.hk"} />]],
        [t("registration.faqQ4"), t("registration.faqContent4"), [<a href={`https://aif2023-reg.apac-event.com/Virtual` + baseUrl}  target={"_blank"}/>, <br/>, <a href={"mailto:aif@ia.org.hk"} />]],
        [t("registration.faqQ5"), t("registration.faqContent5"), [<a href={`https://aif2023-reg.apac-event.com/Virtual` + baseUrl}  target={"_blank"}/>, <br/>, <a href={"mailto:aif@ia.org.hk"} />]],
        [t("registration.faqQ6"), t("registration.faqContent6"), [<a href={`https://aif2023-reg.apac-event.com/Virtual` + baseUrl}  target={"_blank"}/>, <br/>, <a href={"mailto:aif@ia.org.hk"} />]]
    ]
    const faqList2 = [
        [t("registration.faqQ7"), t("registration.faqContent7"), [<a href={`https://www.ia.org.hk` + baseUrl + `/legislative_framework/files/GL24.pdf`}  target={"_blank"}/>, <br/>, <a href={"mailto:aif@ia.org.hk"} />]],
        [t("registration.faqQ8"), t("registration.faqContent8"), [<a href={`https://aif2023-reg.apac-event.com/Virtual` + baseUrl}  target={"_blank"}/>, <br/>, <a href={"mailto:aif@ia.org.hk"} />]],
        [t("registration.faqQ9"), t("registration.faqContent9"), [<a href={`https://aif2023-reg.apac-event.com/Virtual` + baseUrl}  target={"_blank"}/>, <br/>, <a href={"mailto:aif@ia.org.hk"} />]]
    ]

    function handleLink(e) {
        history.push(e);
    }


    const speakerContentVariants = {
        initial: { opacity: 0, y: 100},
        animate: {
            opacity: 1,
            y: 0,
        }
    };
    const programmeListVariants = {
        initial: { opacity: 0},
        animate: {
            opacity: 1,
        }
    };
    const programmeListVariants2 = {
        initial: { opacity: 0},
        animate: {
            opacity: 1,
        }
    };


    const Item = ({index, title, content, tags }) =>{
        const [isOpen, setIsOpen] =  useState(false);

        const toggleOpen = () => setIsOpen(!isOpen);

        // useEffect(()=>{
        //     if (index===0){
        //         setIsOpen(true)
        //     }
        // }, [index])

        return (
            <motion.li layout={true} onClick={toggleOpen} className={`${isOpen? "active":""}`}>
                <motion.div layout={true} >
                    <span className={`${isOpen? "active":""}`}>{title}</span>
                </motion.div>
                <AnimatePresence>{isOpen && <Content content={content} tags={tags}/>}</AnimatePresence>
            </motion.li>
        );
    }

    const Content = ({content, tags }) =>{
        return (
            <motion.div
                className={"faqAns"}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <p className={"justified"}><Trans i18nKey={content} components={tags}></Trans></p>
            </motion.div>
        );
    }


    return (
        <MotionDiv>
            <div className={"insidePageBg"}>
                {/*<div className="bgImg"></div>*/}
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>

                <div className={"insidePageContent registrationPageContent"}>
                    <h1 className={"pageTitle"}>{t("registration.title")}</h1>
                    {/*<div className={"contentWrap"}>*/}
                        {/*<div className={"joinSection"}>*/}
                    {/*        /!*<p><Trans i18nKey={"registration.beforeReady"}></Trans></p>*!/*/}

                    {/*        <a href={`https://aif2023-reg.apac-event.com/Virtual` + baseUrl}  className={"registerBtn btnWithHover"} target={"_blank"}>*/}
                    {/*            {t("registration.registerNow")}*/}
                    {/*        </a>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className={"faqSection"}>
                        <div className={"contentWrap"}>
                            <h2 className={"sectionTitle"}>{t("registration.faq")}</h2>
                            <br/>
                            <AnimatePresence>
                                <LayoutGroup>
                                    <br/>
                                    <h3 className={"subSectionTitle"}>{t("registration.aboutReg")}</h3>
                                    <motion.ul >
                                        {faqList&&faqList.map((faq, index) => (
                                            <Item title={faq[0]} content={faq[1]} index={index} key={index} tags={faq[2]}/>
                                        ))}
                                    </motion.ul>
                                    <br/>
                                    <br/>
                                    <h3 className={"subSectionTitle"}>{t("registration.CPDHours")}</h3>
                                    <motion.ul >
                                        {faqList2&&faqList2.map((faq, index) => (
                                            <Item title={faq[0]} content={faq[1]} index={index} key={index} tags={faq[2]}/>
                                        ))}
                                    </motion.ul>
                                    {/*<br/>*/}
                                    {/*<br/>*/}
                                    {/*<motion.ul >*/}
                                    {/*    <Item title={t("registration.houseRule")} content={t("registration.houseRuleContent")}  tags={[<u/>, <a href={"/assets/pdf/House_rules_AIF_2023_clean_1204.pdf"} download/>]}/>*/}
                                    {/*</motion.ul>*/}


                                </LayoutGroup>
                            </AnimatePresence>
                        </div>
                    </div>

                    <div className={"disclaimerSection"}>
                        {/*<div className={"contentWrap"}>*/}
                        {/*    <i><u>{t("")}</u></i>*/}
                        {/*    <p className={"justified"}><i><Trans i18nKey={""} components={[<u/>, <a href={"/assets/pdf/House_rules_AIF_2023_clean_1204.pdf"} download/>]} ></Trans></i></p>*/}
                        {/*</div>*/}
                        {/*<br/>*/}
                        {/*<br/>*/}

                        <div className={"contentWrap"}>
                            <i><u>{t("registration.disclaimer")}</u></i>
                            <p className={"justified"}><i><Trans i18nKey={"registration.disclaimerContent"} components={[<br/>, <a href={"/"} />]} ></Trans></i></p>

                        </div>
                    </div>


                </div>
            </div>
        </MotionDiv>
    );
}

export default Registration;
