const initState = {
    cyList:{}
};

// const itemReducer = (state = initState, action) => {
//     switch (action.type) {
//       case 'ADD_ITEM': {
//         const menuItemCopy = state.menuItemData.slice();
//         return { menuItemData: [action.payload.itemNew].concat(menuItemCopy) };
//       }
//       case 'CLEAN_ITEM': {
//         return { menuItemData: [] };
//       }
//       default:
//         return state;
//     }
// };



const cyListReducer = (state = initState, action) => {
	switch(action.type) {
		case 'ADD_FLOOR': {
			return {
				// state = {
					...state,
					cyList: {
						...state.cyList,
						[action.payload]:{
                            ...state.cyList[action.payload],
							cytoscapeSetting: "",
							element: "",
						
						}
	
					}

				// }
			}
		}
  
		case 'ADD__CYTO_ELEMENT': {
			const { floorId, cytoscapeSetting, element } = action.payload
			// return {
				state = {
					...state,
					cyList: {
						...state.cyList,
						[floorId]: {
							...state.cyList[floorId],
							cytoscapeSetting: cytoscapeSetting,
							element: element
						}

					}
				}
			// }
		}

		case 'RESET_PATH': {
			const { floorId, escPath, liftPath, accLiftPath } = action.payload
			return {
				// state = {
					...state,
					cyList: {
						...state.cyList,
						[floorId]: {
							...state.cyList[floorId],
							path: {
								escalator: escPath, 
                                lift: liftPath, 
                                accLift: accLiftPath
							}
						}
					}
				}
			// }
		}
  
		case 'ADD_ESCPATH': {
			const { floorId, path } = action.payload
			// return {
				state = {
					...state,
					cyList: {
						...state.cyList,
						[floorId]: {
							...state.cyList[floorId],
							path: {
								...state.cyList[floorId].path,
								escalator: [path]
							}
						}
					}
				}
			// }
		}
		case 'ADD_LIFTPATH': {
			const { floorId, path } = action.payload
			// return {
				state = {
					...state,
					cyList: {
						...state.cyList,
						[floorId]: {
							...state.cyList[floorId],
							path: {
								...state.cyList[floorId].path,
								lift: [path]
							}
						}
					}
				}
                
			// }
		}
  
		case 'ADD_ACCLIFTPATH': {
			const { floorId, path } = action.payload
			// return {
				state = {
					...state,
					cyList: {
						...state.cyList,
						[floorId]: {
							...state.cyList[floorId],
							path: {
								...state.cyList[floorId].path,
								accLift: [path]
							}
						}
					}
				}
		
			}
        
        default: 
            return state;

	}
};

export {cyListReducer};