import {useHistory, Link, useParams, useLocation} from "react-router-dom";
import Button from "../../UI/Button";
import Languageswitcher from "./Languageswitcher";
import menuBtnImg from "../../../assets/images/share/btn_menu.svg";
import closeBtnImg from "../../../assets/images/share/btn_close.svg";
import fontBtnImg from "../../../assets/images/share/icon_font.svg";
import emailBtnImg from "../../../assets/images/share/icon_email.svg";
import wtsappIconGreen from "../../../assets/images/share/icon_wtsapp_green.svg";
import wechatIconGreen from "../../../assets/images/share/icon_wechat_green.svg";
import fbIconGreen from "../../../assets/images/share/icon_fb_green.svg";
import inIconGreen from "../../../assets/images/share/icon_in_green.svg";
import wtsappIconWhite from "../../../assets/images/share/icon_whatsapp_white.svg";
import wechatIconWhite from "../../../assets/images/share/icon_wechat_white.svg";
import fbIconWhite from "../../../assets/images/share/icon_fb_white.svg";
import inIconWhite from "../../../assets/images/share/icon_in_white.svg";
import React, {useState, useEffect, useRef} from "react";
import {Trans, useTranslation} from "react-i18next";
import {AnimatePresence, motion, useAnimation, useScroll} from "framer-motion";
import downWhiteArrow from "../../../assets/images/share/arrow_down_white.svg";
import useCheckDeviceMode from "../../hooks/useCheckDeviceMode";
import i18n from "../../../i18n";
import menuBg from "../../../assets/images/share/bg_menu.jpg"
import {InlineShareButtons} from 'sharethis-reactjs';

function Header() {
  const { t, i18n } = useTranslation();
  const currentLng = t.language;
  const history = useHistory();
  const [menuOpened, setMenuOpened] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [scriptAdded, setScriptAdded] = useState(false);
  const [addThis, setAddThis] = useState();
  const responsiveMode = useCheckDeviceMode();
  const baseUrl = '/'+i18n.language;
  const lang = i18n.language;
  const { scrollYProgress } = useScroll();
  let location = useLocation();
  const controls = useAnimation();

  function handleLink(e) {
    window.location.replace(e);
  }

  function toHome(e) {
    history.push(baseUrl)
  }


  function toggleMenu(){
    setMenuOpened(!menuOpened);

  }

  useEffect(() => {
    setMenuOpened(false)
  }, [location]);

  useEffect(() => {
    const script = document.createElement("script");
    // script.src = "//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-6323fa15a237ffb1";
    // script.async = true;
    // script.onload = () => {
    //   window.addthis.init()
    //   setAddThis(window.addthis)
    //   // console.log(window.addthis)
    //   // console.log(window.addthis.layers)
    //   // console.log(window.addthis.layers.initialize)
    //   // window.addthis.layers.refresh()
    //
    // };
    //
    // document.body.appendChild(script);
    // setScriptAdded(true)

    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  const menuVariants = {
    initial: { opacity: 0, y: 50},
    animate: {
      opacity: 1,
      y: 0,
    }
  };

  useEffect(()=>{
    if (menuOpened){
      controls.start("visible");

      if (responsiveMode!=="desktop-view"&&addThis&&addThis.layers&&addThis.layers.refresh){

        // addThis.layers.refresh()
      }
    }
  }, [menuOpened, responsiveMode, addThis])

  //
  // useEffect(()=>{
  //   if (addThis&&addThis.layers&&addThis.layers.refresh&&i18n.language){
  //     console.log("refresh")
  //     console.log(addThis)
  //     console.log(addThis.toolbox(".addthis_inline_share_toolbox"))
  //     addThis.init()
  //     addThis.layers.refresh()
  //
  //     addThis.update('share', 'url', `/`+i18n.language);
  //     addThis.update('share', 'title', t("meta.title"));
  //     addThis.update('share', 'description', t("meta.desc"));
  //     // addThis.toolbox(".addthis_inline_share_toolbox");
  //     // addThis.layers.refresh()
  //
  //     // addThis.update('share', 'url', `/`+i18n.language);
  //     // addThis.url = `/`+i18n.language;
  //     addThis.toolbox(".addthis_inline_share_toolbox_ij2y");
  //     addThis.layers.refresh()
  //   }
  //
  // }, [i18n.language, addThis])




  return (
    <>
      <header className={(scroll? "scrolled": "")}>
      {/*<header className={`withEmer `+(scroll? "scrolled": "")}>*/}

        <div className={"headerWrap"}>
          <div className="logo-wrap">
            {/*<Link to={baseUrl + `/`} >{t("common.pages.programme")}</Link>*/}
            <Link to={baseUrl + `/`} className={"aif-logo"}>
              Asian Insurance Forum 2023
            </Link>
          </div>

          <div className={"btnsWrap"}>
            <Button className={"menuBtn"} function={toggleMenu}>
              <img src={menuBtnImg}/>
            </Button>
            <Languageswitcher/>
          </div>


          <AnimatePresence mode={"wait"} exitBeforeEnter initial={false}>

            {menuOpened && (
                <motion.div
                    className={`menu ${menuOpened? "opened":""}`}
                    // initial={"initial"}
                    // animate={"an"}
                    // exit={"initial"}
                    // variants={menuVariants}
                    initial={{opacity: 0, y: 50}}
                    animate={{opacity: 1, y: 0, backgroundImage: `url(${menuBg})`}}
                    exit={{opacity: 0, y: 50}}
                    // initial={{opacity: 0}}
                    // animate={{opacity: 1}}
                    // exit={{opacity: 0}}  animate={{ x: 100 }}
                    transition={{ ease: "easeInOut", duration: 0.5, type: "spring" }}
                    // key={current}

                >
                  <Button className={"closeMenuBtn"} function={toggleMenu}>
                    <img src={closeBtnImg}/>
                  </Button>
                  <div className={"panelWrap"}>
                    {responsiveMode==="desktop-view"?
                        <div className={"leftPanel"}>
                          <h2>
                            <Trans i18nKey="common.title"></Trans>
                          </h2>
                          <p>{t("home.banner.description")}</p>
                          <div className={"btnsWrap"}>
                            <Button className={"fontSizeBtn"} >
                              <Link to={baseUrl + `/accessibility`}>
                                <img src={fontBtnImg}/>
                              </Link>
                            </Button>
                            <Button className={"emailBtn"}>
                              <a href={"mailto:aif@ia.org.hk"}>
                                <img src={emailBtnImg}/>

                              </a>
                            </Button>
                          </div>
                        </div>:""
                    }
                    <div className={"rightPanel"}>
                      <ul>
                        <li><Link to={baseUrl + `/programme`} onClick={toggleMenu}>{t("common.pages.programme")}</Link></li>
                        <li><Link to={baseUrl + `/speakers`} onClick={toggleMenu}>{t("common.pages.speakers")}</Link></li>
                        {/*<li><Link to={baseUrl + `/registration`} onClick={toggleMenu}>{t("common.pages.registration")}</Link></li>*/}
                        <li><Link to={baseUrl + `/information-center`} onClick={toggleMenu}>{t("common.pages.infoCenter")}</Link></li>
                        <li><Link to={baseUrl + `/past-highlight`} onClick={toggleMenu}>{t("common.pages.pastHighlight")}</Link></li>
                        {/*<li><Link to={`/programme`} onClick={toggleMenu}>{t("common.pages.programme")}</Link></li>*/}
                        {/*<li><Link to={`/speakers`} onClick={toggleMenu}>{t("common.pages.speakers")}</Link></li>*/}
                        {/*<li><Link to={`/registration`} onClick={toggleMenu}>{t("common.pages.registration")}</Link></li>*/}
                        {/*<li><Link to={`/information-center`} onClick={toggleMenu}>{t("common.pages.infoCenter")}</Link></li>*/}
                        {/*<li><Link to={`/past-highlight`} onClick={toggleMenu}>{t("common.pages.pastHighlight")}</Link></li>*/}
                        {/*<li><Link to={`/:lng/programme`} onClick={toggleMenu}>{t("common.pages.programme")}</Link></li>*/}
                        {/*<li><Link to={`/:lng/speakers`} onClick={toggleMenu}>{t("common.pages.speakers")}</Link></li>*/}
                        {/*<li><Link to={`/:lng/registration`} onClick={toggleMenu}>{t("common.pages.registration")}</Link></li>*/}
                        {/*<li><Link to={`/:lng/information-center`} onClick={toggleMenu}>{t("common.pages.infoCenter")}</Link></li>*/}
                        {/*<li><Link to={`/:lng/past-highlight`} onClick={toggleMenu}>{t("common.pages.pastHighlight")}</Link></li>*/}
                      </ul>
                    </div>
                  </div>


                  {responsiveMode !== "desktop-view" ?
                      <div className={"mobileShareWrap"}>
                        <span>{t("header.share")}</span>
                        {/*<div className="addthis_inline_share_toolbox" data-url={`/`+i18n.language} ></div>*/}
                        <div>
                          <InlineShareButtons
                              config={{
                                alignment: 'center',  // alignment of buttons (left, center, right)
                                color: 'social',      // set the color of buttons (social, white)
                                enabled: true,        // show/hide buttons (true, false)
                                font_size: 16,        // font size for the buttons
                                labels: 'null',        // button labels (cta, counts, null)
                                language: 'en',       // which language to use (see LANGUAGES)
                                networks: [           // which networks to include (see SHARING NETWORKS)
                                  'whatsapp',
                                  'wechat',
                                  'facebook',
                                  'linkedin'
                                ],
                                padding: 12,          // padding within buttons (INTEGER)
                                radius: 4,            // the corner radius on each button (INTEGER)
                                show_total: false,
                                size: 50,             // the size of each button (INTEGER)
                              }}
                          />
                          {/*<a href={"/"}>*/}
                          {/*  <img src={wtsappIconWhite}/>*/}
                          {/*</a>*/}
                          {/*<a href={"/"}>*/}
                          {/*  <img src={wechatIconWhite}/>*/}
                          {/*</a>*/}
                          {/*<a href={"/"}>*/}
                          {/*  <img src={fbIconWhite}/>*/}
                          {/*</a>*/}
                          {/*<a href={"/"}>*/}
                          {/*  <img src={inIconWhite}/>*/}
                          {/*</a>*/}
                        </div>
                      </div>:""
                  }
                </motion.div>
            )}
          </AnimatePresence>


          {responsiveMode === "desktop-view" ?
              <div className={`desktopShareWrap ${responsiveMode === "desktop-view" ? "":"hidden"}`}>
                <span>{t("header.share")}</span>

                {/*{i18n.language==="en"?<div className="addthis_inline_share_toolbox_lxcl" data-url={`/en`}>en</div>:*/}
                {/*    i18n.language==="tc"?<div className="addthis_inline_share_toolbox_lxcl" data-url={`/tc`}>tc</div>:*/}
                {/*        <div className="addthis_inline_share_toolbox_lxcl" data-url={`/sc`}>sc</div>}*/}


                <InlineShareButtons
                    config={{
                      alignment: 'center',  // alignment of buttons (left, center, right)
                      color: 'social',      // set the color of buttons (social, white)
                      enabled: true,        // show/hide buttons (true, false)
                      font_size: 16,        // font size for the buttons
                      labels: 'null',        // button labels (cta, counts, null)
                      language: 'en',       // which language to use (see LANGUAGES)
                      networks: [           // which networks to include (see SHARING NETWORKS)
                        'whatsapp',
                        'wechat',
                        'facebook',
                        'linkedin',
                      ],
                      padding: 12,          // padding within buttons (INTEGER)
                      radius: 4,            // the corner radius on each button (INTEGER)
                      show_total: false,
                      size: 50,             // the size of each button (INTEGER)
                    }}
                />

                {/*<a href={"/"}>*/}
                {/*  <img src={wtsappIconWhite}/>*/}
                {/*</a>*/}
                {/*<a href={"/"}>*/}
                {/*  <img src={wechatIconWhite}/>*/}
                {/*</a>*/}
                {/*<a href={"/"}>*/}
                {/*  <img src={fbIconWhite}/>*/}
                {/*</a>*/}
                {/*<a href={"/"}>*/}
                {/*  <img src={inIconWhite}/>*/}
                {/*</a>*/}

              </div>
              :""}

          <div className="logo-wrap">
            <a href={"https://www.ia.org.hk/"+i18n.language} target={"_blank"} className={"ia-logo"}>
              Insurance　Authority
            </a>
          </div>
        </div>

      </header>
    </>
  );
}

export default Header;
