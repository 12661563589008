import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import customPath from './i18n/customPaths';

const languageDetector = new LanguageDetector();

languageDetector.addDetector(customPath);

// i18n.on('languageChanged', function (lng) {
//
//     console.log("lng", lng)
//     console.log("fallbackLng[0]", i18n.options.fallbackLng[0])
//     if (lng === i18n.options.fallbackLng[0]) {
//         if (window.location.pathname.includes('/' + i18n.options.fallbackLng[0])) {
//             const newUrl = window.location.pathname.replace(
//                 '/' + i18n.options.fallbackLng[0]
//             );
//             window.location.replace(newUrl);
//         }
//     }
// });


const options = {

    // order: ['path', 'cookie', 'htmlTag'],
    order: ['customPath', 'localStorage', 'querystring',  'navigator'],
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupSessionStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,

    // caches: [ 'localStorage', 'cookie', ],
    excludeCacheFor: ['cimode'],

    cookieMinutes: 10,
    cookieDomain: 'myDomain',

    htmlTag: document.documentElement,

    cookieOptions: { path: '/', sameSite: 'strict' }
};
i18n
    .use(Backend)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .use(LanguageDetector)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,
        lng: localStorage.getItem("i18nextLng")||'en',
        fallbackLng: 'en',
        whitelist: ['en', 'tc', 'sc'],
        ns: ['common'],
        defaultNS: 'common',
        detection: options,
        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            loadPath: `/locales/{{lng}}/{{ns}}.json`,
            requestOptions: {
                cache: 'no-store',
            },
        }
    });

export default i18n;