const initState = {
    polylineRef:{}
};
const polylineRefReducer = (state = initState, action) => {

	switch(action.type) {
		case 'ADD_POLYLINEREF': {
			const { floorId, object } = action.payload
            state = {
                ...state,
                polylineRef: {
                    ...state.polylineRef,
                    [floorId]: object
                }
            }
		}
        default: 
            return state;

    }
};

export {polylineRefReducer};