const initState = {
    walkDurationList:{}
};
const walkDurationListReducer = (state = initState, action) => {

	switch(action.type) {
		case 'ADD_WALKDURATION': {
			const { floorId, object } = action.payload
            return {
                ...state,
                walkDurationList: {
                    ...state.walkDurationList,
                    [floorId]: object

                }
            }
		}

        case 'RESET_WALKDURATION': {
            // const { floorId, object } = action.payload
            return {
                ...state,
                walkDurationList: {}
            }
        }
        default: 
            return state;

    }
};

export {walkDurationListReducer};