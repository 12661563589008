const initState = {
    highlightList:[]
};
const highlightListReducer = (state = initState, action) => {

	switch(action.type) {
		case 'ADD_HIGHLIGHT': {
			const { object } = action.payload
            return {
            // state = {
                ...state,
                highlightList: [
                    ...state.highlightList,
					object
				]
            }
		}
		case 'RESET_HIGHLIGHT': {
			return {
				// state = {
					...state,
					highlightList: []
				}
			// }
		}

        default: 
            return state;

	}
};

export {highlightListReducer};