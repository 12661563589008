import React, {useEffect, useState, useRef, useLayoutEffect} from "react";
import MotionDiv from "../../components/UI/MotionDiv";
import { useTranslation, Trans } from "react-i18next";
import ParticleImage, {
    ParticleOptions,
    Vector,
    forces,
    ParticleForce
} from "react-particle-image";
import bannerImg from "../../assets/images/home/logo_banner.png"
import arrowImg from "../../assets/images/home/img_arrow.png"
import halfArrowImg from "../../assets/images/home/img_half_arrow.png"
import orangeRightArrow from "../../assets/images/share/arrow_right_orange.svg"
import whiteLeftArrow from "../../assets/images/share/arrow_left_white.svg"
import whiteRightArrow from "../../assets/images/share/arrow_right_white.svg"
import bgVid from "../../assets/video/bg.mp4"
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import "./index.scss";
import Button from "../../components/UI/Button";
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Pagination, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import {AnimatePresence, motion, useTransform, useViewportScroll} from "framer-motion";
import FadeInOnScrollDiv from "../../components/UI/FadeInOnScrollDiv";
import useCheckDeviceMode from "../../components/hooks/useCheckDeviceMode";
import {useHistory, Link} from "react-router-dom";
import gsap from "gsap";
import openBtn from "../../assets/images/share/btn_open.svg";
import { TweenMax } from "gsap/all";

function Home() {
    const { t, i18n } = useTranslation();
    const [currentLng, setCurrentLng] = useState(i18n.language);

    const baseUrl = '/'+i18n.language;
    const responsiveMode = useCheckDeviceMode();
    const [livecastLang, setLivecastLang] = useState("en")

    // const swiper = useSwiper();
    const [speakerSwiper, setSpeakerSwiper] = useState();
    const [disablePrev, setDisablePrev] = useState(false);
    const [disableNext, setDisableNext] = useState(false);
    const [participantSwiper, setParticipantSwiper] = useState();
    const prevRef = useRef();
    const nextRef = useRef();
    const elementRef = useRef();

    const [speakerList, setSpeakerList] = useState([]);

    const lastSectionRef = useRef();
    const [offsetTop, setOffsetTop] = useState(0);
    const [minHeight, setMinHeight] = useState("auto");
    const [arrowOffsetTop, setArrowOffsetTop] = useState(0);
    const [arrowOffsetHeight, setArrowOffsetHeight] = useState("auto");
    const arrowRef = useRef();
    const [scroll, setScroll] = useState(false);
    const [isLive, setIsLive] = useState(false);
    const [MousePosition, setMousePosition] = useState({
        left: 0,
        top: 0
    })

    const { scrollY } = useViewportScroll();
    const range = 0.2;
    const rand = (min = 0, max = 100) => {
        return Math.floor(Math.random() * (+max - +min)) + +min;
    };
    const springConfig = {
        damping: 100,
        stiffness: 100,
        mass: rand(1, 3)
    };

    const calculateMinHeight = (height, range) => {
        return height + height * range;
    };

    useLayoutEffect(() => {
        if (!lastSectionRef.current) return null;
        const onResize = () => {
            setOffsetTop(lastSectionRef.current.offsetTop);
            setMinHeight(calculateMinHeight(lastSectionRef.current.offsetHeight, range));
        };

        onResize();
        window.addEventListener("resize", onResize);

        return () => window.removeEventListener("resize", onResize);
    }, [lastSectionRef]);




    const opacity = useTransform(scrollY,   // Map x from these values:
        [0, 400],
        // Into these values:
        [1, 0], { clamp: true });

    const zIndex = useTransform(scrollY,   // Map x from these values:
        [0, 1500],
        // Into these values:
        [1, -1], { clamp: true });
    // const display = useTransform(scrollY,   // Map x from these values:
    //     [0, 1500],
    //     // Into these values:
    //     ["block", "none"], { clamp: true });

    const particleOptions= {
        filter: ({ x, y, image }) => {
            // Get pixel
            const pixel = image.get(x, y);
            // Make a particle for this pixel if blue > 50 (range 0-255)
            return pixel.b > 50;
        },
        radius: () => Math.random() * 2.5 + 0.5,
        mass: () => 50,
        friction: () => 0.15,
        initialPosition: ({ canvasDimensions }) => {
            return new Vector(0, canvasDimensions.height);
        }
        // color: ({ x, y, image }) => "#61dafb"
    };
    const particleOptions2= {
        filter: ({ x, y, image }) => {
            // Get pixel
            const pixel = image.get(x, y);
            // Make a particle for this pixel if blue > 50 (range 0-255)
            return pixel.b > 50;
        },
        radius: () => Math.random() * 1.5 + 0.5,
        mass: () => 50,
        friction: () => 0.15,
        initialPosition: ({ canvasDimensions }) => {
            return new Vector(0, canvasDimensions.height);
        },
        color: ({ x, y, image }) => "#9CC813"
    };

    const motionForce = (x, y) => {
        return forces.disturbance(x, y, 5);
    };

    const minuteSeconds = 60;
    const hourSeconds = 3600;
    const daySeconds = 86400;

    const timerProps = {
        isPlaying: true,
        size: 120,
        strokeWidth: 6
    };
    function pad(d) {
        return (d < 10) ? '0' + d.toString() : d.toString();
    }
    const renderTime = (dimension, time) => {

        return (
            <div className="time-wrapper">
                <div className="time">{pad(time)}</div>
                <div className={"unit"}>{dimension}</div>
            </div>
        );
    };

    const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
    const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
    const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
    const getTimeDays = (time) => (time / daySeconds) | 0;

    const startTime = Date.now() / 1000; // use UNIX timestamp in seconds
    const endDate = new Date("2023-12-08T08:30:00.000+08:00")
    const endTime = endDate.getTime() / 1000; // use UNIX timestamp in seconds

    const remainingTime = endTime - startTime;
    const days = Math.ceil(remainingTime / daySeconds);
    const daysDuration = days * daySeconds;

    const history = useHistory();
    function handleLink(e) {
        history.push(e);
    }

    // useEffect(()=>{
    //     setCurrentLng(i18n.language)
    // }, [i18n.language])

    useEffect(()=>{
        if (i18n.language){
            // console.log(i18n.language)

            if (i18n.language==="tc"){
                setLivecastLang("zh-Hant")
            }else if(i18n.language==="sc"){
                setLivecastLang("zh-Hans")
            }else{
                setLivecastLang("en")

            }
            const fetchSpeakers = async () => {
                fetch("/assets/data/speakers_"+i18n.language+".json", {
                    headers : {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                })
                    .then(res => res.json())
                    .then((result) => {
                        const perChunk = 4 // items per chunk

                        const inputArray = Object.values(result.speakers)
                        // console.log("inputArray", inputArray)
                        const filteredArray = inputArray.filter(speakers => speakers["img"]!=="/assets/images/speakers/placeholder.png"&&speakers["content"]!=="n/a");
                        // console.log("filteredArray", filteredArray)

                        const speakerList = filteredArray.reduce((resultArray, item, index) => {
                            const chunkIndex = Math.floor(index/perChunk)

                            if(!resultArray[chunkIndex]) {
                                resultArray[chunkIndex] = [] // start a new chunk
                            }

                            // if (item.img!=="/assets/images/speakers/placeholder.png"&&item.content!=="n/a"){
                                resultArray[chunkIndex].push(item)

                            // }
                            return resultArray
                        }, [])

                        // console.log("speakerList", speakerList)
                        setSpeakerList(speakerList)
                    })
                    .catch((err) => {
                        console.log('err:', err);
                        // handle timeout msg
                    });

            };
            fetchSpeakers();

            setCurrentLng(i18n.language)
        }
    }, [i18n.language])

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 2000);
        });

        let liveTime = new Date("2023-12-08T08:30:00.000+08:00");
        let finishTime = new Date("2023-12-08T17:05:00.000+08:00")

        if (new Date() >= liveTime){
            setIsLive(true)
        }else{
            let timeDiff = liveTime.getTime() - Date.now()
            function timeoutFunc(){
                setIsLive(true)
            }
            setTimeout(timeoutFunc, timeDiff);
        }
    }, []);


    function handleMouseMove(e) {
        // var wx = window.innerWidth;
        // var wy = window.innerHeight;
        //
        // var x = e.pageX - document.getElementsByTagName('html')[0].offsetLeft;
        // var y = e.pageY - document.getElementsByTagName('html')[0].offsetTop;
        //
        // var newx = x - wx/2;
        // var newy = y - wy/2;
        //
        // Array.from(document.getElementsByClassName('hoverEl')).forEach((element) => {
        //     var speed = element.getAttribute('data-speed');
        //     if(element.getAttribute('data-speed')) speed *= -1;
        //     TweenMax.to(element, 1, {x: (1 - newx*speed), y: (1 - newy*speed)});
        //
        // });
    }

    return (

        <MotionDiv style={{display: 'flex'}}>

            <div
                className="home-banner-wrap"
                ref={elementRef}
                onMouseMove={(ev)=> handleMouseMove(ev)}
                 // style={{left:MousePosition.left , top: MousePosition.top}}
            >
                <div className={"contentWrap"}>


                    <video id="background-video" autoPlay={true} loop={true} muted={true} playsInline >
                        <source src={bgVid} type="video/mp4" />
                    </video>

                    <AnimatePresence>
                        <motion.div
                            className={`arrowsWrap ${scroll? "scrolled": ""}`}
                            initial={{x: 200, y: -200}}
                            animate={{x: 10,  y: 10}}
                            ref={arrowRef}
                            style={{ opacity: opacity }}
                        >

                            <img src={halfArrowImg} className="halfArrow arrow hoverEl" data-speed={'0.1'}/>
                            <img src={arrowImg} className="arrow3 arrow hoverEl" data-speed={'0.2'}/>
                            <img src={arrowImg} className="arrow2 arrow hoverEl" data-speed={'0.12'} />
                            <img src={arrowImg} className="arrow1 arrow hoverEl" data-speed={'0.05'} />

                        </motion.div>


                    </AnimatePresence>
                    <div className={"home-banner-content-wrap"} data-speed={'0.13'}>
                        <div>
                            <h1><Trans i18nKey="home.banner.title"></Trans></h1>
                            <b>{t("home.banner.description")}</b>
                            <p>{t("home.banner.date")}</p>
                            {/*<div className={"dateWrap"}>*/}
                            {/*    {t("home.banner.date")}*/}
                            {/*</div>*/}

                            <div className={"bannerBottomContent"}>
                                {/*<div className={"btnsWrap"}>*/}
                                {/*    {isLive?*/}
                                {/*        <a href={`https://aif2023-live.apac-event.com/?lang=` + livecastLang}  className={"registerBtn liveBtn btnWithHover"} target={"_blank"}>*/}
                                {/*            {t("common.liveWebcast")}*/}
                                {/*        </a>:""}*/}
                                {/*    <a href={`https://aif2023-reg.apac-event.com/Virtual` + baseUrl}  className={"registerBtn btnWithHover"} target={"_blank"}>*/}
                                {/*        {t("common.register")}*/}
                                {/*    </a>*/}
                                {/*</div>*/}

                                {/*{isLive? <span>{t("home.banner.regRequired")}</span> :""}*/}

                                {isLive? "" :
                                    <div className={"timeWrap"}>
                                        <CountdownCircleTimer
                                            {...timerProps}
                                            colors="#1CE9CE"
                                            trailColor={"#ffffff"}
                                            duration={daysDuration}
                                            initialRemainingTime={remainingTime}
                                            size={responsiveMode!="mobile-view"?87: 80}
                                            // size={responsiveMode!="mobile-view"?120: 81}
                                        >
                                            {({ elapsedTime, color }) => (
                                                <span style={{ color }}>
                                            {renderTime(t("common.days"), getTimeDays(daysDuration - elapsedTime))}
                                        </span>
                                            )}
                                        </CountdownCircleTimer>
                                        <CountdownCircleTimer
                                            {...timerProps}
                                            colors="#1CE9CE"
                                            trailColor={"#ffffff"}
                                            duration={daySeconds}
                                            initialRemainingTime={remainingTime % daySeconds}
                                            size={responsiveMode!="mobile-view"?87: 80}
                                            // size={responsiveMode!="mobile-view"?112: 81}
                                            onComplete={(totalElapsedTime) => ({
                                                shouldRepeat: remainingTime - totalElapsedTime > hourSeconds
                                            })}
                                        >
                                            {({ elapsedTime, color }) => (
                                                <span style={{ color }}>
                                            {renderTime(t("common.hours"), getTimeHours(daySeconds - elapsedTime))}
                                        </span>
                                            )}
                                        </CountdownCircleTimer>
                                        <CountdownCircleTimer
                                            {...timerProps}
                                            colors="#1CE9CE"
                                            trailColor={"#ffffff"}
                                            duration={hourSeconds}
                                            initialRemainingTime={remainingTime % hourSeconds}
                                            size={responsiveMode!="mobile-view"?87: 80}
                                            // size={responsiveMode!="mobile-view"?112: 81}
                                            onComplete={(totalElapsedTime) => ({
                                                shouldRepeat: remainingTime - totalElapsedTime > minuteSeconds
                                            })}
                                        >
                                            {({ elapsedTime, color }) => (
                                                <span style={{ color }}>
                                            {renderTime(t("common.minutes"), getTimeMinutes(hourSeconds - elapsedTime))}
                                        </span>
                                            )}
                                        </CountdownCircleTimer>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FadeInOnScrollDiv className={"homeSection whatIsAifSection"} >
                <div className={"contentWrap"}>
                    <h2 className={"homeSectionHeader"}>{t("home.whatIsAif.title")}</h2>
                    <p className={"centered"}>{t("home.whatIsAif.content")}</p>
                    <Link to={baseUrl + `/programme`} className={"sectionLink"} >
                        <span>{t("home.whatIsAif.viewProgramme")}</span>
                    </Link>
                </div>
            </FadeInOnScrollDiv>
            <FadeInOnScrollDiv className={"homeSection speakerSection"}>
                <div className={"contentWrap"}>
                    <h2 className={"homeSectionHeader"}>{t("home.speakers.title")}</h2>
                    <p>{t("speakers.ps")}</p>
                    <Swiper
                        spaceBetween={30}
                        slidesPerView={1}
                        speed={500}
                        loop={false}
                        autoHeight={true}
                        navigation
                        pagination={responsiveMode==="desktop-view"? false: true}
                        modules={[Pagination, Scrollbar]}
                        scrollbar={{ draggable: true }}
                        initialSlide={0}
                        onSwiper={(swiper) => {
                            setSpeakerSwiper(swiper)
                        }}
                        onActiveIndexChange={(swiper) => {
                            if (swiper.realIndex===0){
                                setDisablePrev(true)
                                setDisableNext(false)
                            }else if (swiper.realIndex===4){
                                setDisableNext(true)
                                setDisablePrev(false)
                            }else{
                                setDisablePrev(false)
                                setDisableNext(false)
                            }
                        }}
                        className={"speakersWrap"}>

                        {responsiveMode==="desktop-view"?
                            <div className={"swiperBtnsWrap"}>

                                <button onClick={() => speakerSwiper.slidePrev()} className={`swiperBtn btnWithHover prevBtn ${disablePrev?"diabled": ""}`}>
                                    <img src={whiteLeftArrow} />
                                </button>
                                <button onClick={() => speakerSwiper.slideNext()} className={`swiperBtn btnWithHover nextBtn ${disableNext?"diabled": ""}`}>
                                    <img src={whiteRightArrow} />

                                </button>
                            </div>
                            :""
                        }
                        {speakerList&&speakerList.length>1&&speakerList.map((slide, index)=>{
                            return (
                                <SwiperSlide key={index}>
                                    {slide.length>0&&slide.map((speaker, i)=>{
                                        return(
                                            <div className={"speakerWrap"} key={index+i}>

                                                <div className={"imgOuterWrap"}>
                                                    <div className={"imgInnerWrap"}>
                                                        <img src={speaker.img} />
                                                    </div>
                                                </div>
                                                {/*<div className={"speakerImgWrap"}>*/}
                                                {/*    <img className={"speakerImg"} src={speaker.img}/>*/}

                                                {/*</div>*/}
                                                <div className={"speakerDetails"}>
                                                    <div dangerouslySetInnerHTML={{__html: speaker.name,}}/>
                                                    <div dangerouslySetInnerHTML={{__html: speaker.title,}}/>
                                                </div>
                                            </div>
                                            )

                                    })}
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>

                    <Link to={baseUrl + `/speakers`} className={"sectionLink"}>
                        <span>{t("home.speakers.exploreAll")}</span>
                    </Link>
                </div>
            </FadeInOnScrollDiv>
            <FadeInOnScrollDiv className={"homeSection participantsSection"}>
                <div className={"contentWrap"}>
                    <h2 className={"homeSectionHeader"}>{t("home.participants.title")}</h2>

                    <div className={"swiperWrap"}>
                        <Swiper spaceBetween={50}
                                slidesPerView={responsiveMode==="desktop-view"?2: 1}
                                initialSlide={0}
                                speed={500}
                                loop={false}
                                navigation
                                pagination={responsiveMode==="desktop-view"? false: true}
                                modules={[Pagination]}
                                onSwiper={(swiper) => {
                                    setParticipantSwiper(swiper)
                                }}
                                className={"participantsWrap"}
                        >

                            <SwiperSlide>
                                <div className={"participantWrap"}>
                                    <div className={"participantContentWrap"}>
                                        <p className={"justified"}>{t("home.participants.participant1.content")}</p>
                                    </div>
                                    <div className={"participantDetails"}>
                                        <p className={"name"}>{t("home.participants.participant1.name")}</p>
                                        <span>{t("home.participants.participant1.extra")}</span>
                                        <b>{t("home.participants.participant1.department")}</b>
                                        <p>{t("home.participants.participant1.title")}</p>
                                        <span>{t("home.participants.aif2022")}</span>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className={"participantWrap"}>
                                    <div className={"participantContentWrap"}>
                                        <p className={"justified"}>{t("home.participants.participant2.content")}</p>
                                    </div>
                                    <div className={"participantDetails"}>
                                        <p className={"name"}>{t("home.participants.participant2.name")}</p>
                                        <span>{t("home.participants.participant2.extra")}</span>
                                        <b>{t("home.participants.participant2.department")}</b>
                                        <p>{t("home.participants.participant2.title")}</p>
                                        <span>{t("home.participants.aif2022")}</span>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className={"participantWrap"}>
                                    <div className={"participantContentWrap"}>
                                        <p className={"justified"}>{t("home.participants.participant3.content")}</p>
                                    </div>
                                    <div className={"participantDetails"}>
                                        <p className={"name"}>{t("home.participants.participant3.name")}</p>
                                        {/*<span>{t("home.participants.participant3.extra")}</span>*/}
                                        <b>{t("home.participants.participant3.department")}</b>
                                        <p>{t("home.participants.participant3.title")}</p>
                                        <span>{t("home.participants.aif2021")}</span>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className={"participantWrap"}>
                                    <div className={"participantContentWrap"}>
                                        <p className={"justified"}>{t("home.participants.participant4.content")}</p>
                                    </div>
                                    <div className={"participantDetails"}>
                                        <p className={"name"}>{t("home.participants.participant4.name")}</p>
                                        {/*<p>{t("home.participants.participant4.extra")}</p>*/}
                                        <b>{t("home.participants.participant4.department")}</b>
                                        <p>{t("home.participants.participant4.title")}</p>
                                        <span>{t("home.participants.aif2021")}</span>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={"participantWrap"}>
                                    <div className={"participantContentWrap"}>
                                        <p className={"justified"}>{t("home.participants.participant5.content")}</p>
                                    </div>
                                    <div className={"participantDetails"}>
                                        <p className={"name"}>{t("home.participants.participant5.name")}</p>
                                        <p>{t("home.participants.participant5.extra")}</p>
                                        <b>{t("home.participants.participant5.department")}</b>
                                        <p>{t("home.participants.participant5.title")}</p>
                                        <span>{t("home.participants.aif2020")}</span>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={"participantWrap"}>
                                    <div className={"participantContentWrap"}>
                                        <p className={"justified"}>{t("home.participants.participant6.content")}</p>
                                    </div>
                                    <div className={"participantDetails"}>
                                        <p className={"name"}>{t("home.participants.participant6.name")}</p>
                                        <span>{t("home.participants.participant6.extra")}</span>
                                        <b>{t("home.participants.participant6.department")}</b>
                                        <p>{t("home.participants.participant6.title")}</p>
                                        <span>{t("home.participants.aif2020")}</span>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={"participantWrap"}>
                                    <div className={"participantContentWrap"}>
                                        <p className={"justified"}>{t("home.participants.participant7.content")}</p>
                                    </div>
                                    <div className={"participantDetails"}>
                                        <p className={"name"}>{t("home.participants.participant7.name")}</p>
                                        {/*<p>{t("home.participants.participant7.extra")}</p>*/}
                                        <b>{t("home.participants.participant7.department")}</b>
                                        <p>{t("home.participants.participant7.title")}</p>
                                        <span>{t("home.participants.aif2019")}</span>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={"participantWrap"}>
                                    <div className={"participantContentWrap"}>
                                        <p className={"justified"}>{t("home.participants.participant8.content")}</p>
                                    </div>
                                    <div className={"participantDetails"}>
                                        <p className={"name"}>{t("home.participants.participant8.name")}</p>
                                        <p>{t("home.participants.participant8.extra")}</p>
                                        <b>{t("home.participants.participant8.department")}</b>
                                        <p>{t("home.participants.participant8.title")}</p>
                                        <span>{t("home.participants.aif2019")}</span>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>

                    </div>
                    {responsiveMode === "desktop-view" ?
                        <div className={"swiperBtnsWrap"}>
                            <button onClick={() => participantSwiper.slidePrev()} className={"swiperBtn btnWithHover prevBtn"}>
                                <img src={whiteLeftArrow}/>
                            </button>
                            <button onClick={() => participantSwiper.slideNext()} className={"swiperBtn btnWithHover nextBtn"}>
                                <img src={whiteRightArrow}/>

                            </button>
                        </div> : ""
                    }
                </div>
            </FadeInOnScrollDiv>
            <motion.div ref={lastSectionRef} initial={{ y: 0 }} style={{  }} className={"homeSection registerSection"}>
                <div className={"contentWrap"}>
                    <h3><Trans i18nKey="home.bottomBanner.content"></Trans></h3>

                    {/*<Link to={baseUrl + `/registration`} className={"registerBtn btnWithHover"}>*/}
                    {/*    {t("common.register")}*/}
                    {/*</Link>*/}
                </div>
            </motion.div>

        </MotionDiv>

    );
}

export default Home;
