
import MotionDiv from "../../components/UI/MotionDiv";
import { useTranslation, Trans } from "react-i18next";
import Button from "../../components/UI/Button";

import "./index.scss";
import React, {useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import { Frame, motion,  useAnimation, Override, Data, AnimatePresence} from "framer-motion";
import Tilt from 'react-tilt'
import img2022 from "../../assets/images/pastHighlight/img_2022.jpg";
import img2021 from "../../assets/images/pastHighlight/img_2021.jpg";
import img2020 from "../../assets/images/pastHighlight/img_2020.jpg";
import img2019 from "../../assets/images/pastHighlight/img_2019.jpg";
import img2018 from "../../assets/images/pastHighlight/img_2018.jpg";
import openBtn from "../../assets/images/share/btn_open.svg";
import whiteRightArrow from "../../assets/images/share/arrow_right_white.svg";


function PastHighlight() {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const currentLng = t.language;
    // const swiper = useSwiper();
    const [morningActive, setMorningActive] = useState(true);
    const [speakerContent, setSpeakerContent] = useState();


    function handleLink(e) {
        history.push(e);
    }


    const speakerContentVariants = {
        initial: { opacity: 0, y: 100},
        animate: {
            opacity: 1,
            y: 0,
        }
    };
    const programmeListVariants = {
        initial: { opacity: 0},
        animate: {
            opacity: 1,
        }
    };
    const programmeListVariants2 = {
        initial: { opacity: 0},
        animate: {
            opacity: 1,
        }
    };

    return (
        <MotionDiv>
            <div className={"insidePageBg"}>
                {/*<div className="bgImg"></div>*/}
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>

                <div className={"insidePageContent"}>
                    <h1 className={"pageTitle"}>{t("pastHighlights.title")}</h1>
                    <div className={"contentWrap passContent"}>

                        <div className={"highlightWrap"}>
                            <div className={"detailsWrap"}>
                                <h2>2022</h2>
                                <div className={"detailsContent"}>
                                    <p className={"justified"}><Trans i18nKey="pastHighlights.y2022.content" components={[<b/>,<br/>]} /></p>
                                    {/*<Button value="/pasthighlights/2021/" function={(e) => handleLink(e.target.value)} className={"linkBtn"}>*/}
                                    {/*    <img src={whiteRightArrow} />*/}
                                    {/*</Button>*/}
                                </div>
                            </div>

                            <div className="Tilt">
                            {/*<div className="Tilt" options={{ max : 25, scale: 1 }} >*/}
                                <a className="Tilt-inner" target="_blank" href={`/pasthighlights/2022/${i18n.language}`}>
                                    <img src={img2022} />
                                </a>
                            </div>
                        </div>
                        <div className={"highlightWrap"}>
                            <div className={"detailsWrap"}>
                                <h2>2021</h2>
                                <div className={"detailsContent"}>
                                    <p className={"justified"}><Trans i18nKey="pastHighlights.y2021.content" components={[<b/>,<br/>]} /></p>
                                    {/*<Button value="/pasthighlights/2021/" function={(e) => handleLink(e.target.value)} className={"linkBtn"}>*/}
                                    {/*    <img src={whiteRightArrow} />*/}
                                    {/*</Button>*/}
                                </div>
                            </div>

                            <div className="Tilt">
                            {/*<div className="Tilt" options={{ max : 25, scale: 1 }} >*/}
                                <a className="Tilt-inner" target="_blank" href={`/pasthighlights/2021/${i18n.language}`}>
                                    <img src={img2021} />
                                </a>
                            </div>
                        </div>
                        <div className={"highlightWrap"}>
                            <div className={"detailsWrap"}>
                                <h2>2020</h2>
                                <div className={"detailsContent"}>
                                    <p className={"justified"}><Trans i18nKey="pastHighlights.y2020.content" components={[<b/>,<br/>]} /></p>

                                    {/*<Button value="/pasthighlights/2020/" function={(e) => handleLink(e.target.value)} className={"linkBtn"}>*/}
                                    {/*    <img src={whiteRightArrow} />*/}
                                    {/*</Button>*/}
                                </div>
                            </div>

                            <div className="Tilt">
                                <a className="Tilt-inner" target="_blank" href={`/pasthighlights/2020/${i18n.language}`}>
                                    <img src={img2020} />
                                </a>
                            </div>
                        </div>
                        <div className={"highlightWrap"}>
                            <div className={"detailsWrap"}>
                                <h2>2019</h2>
                                <div className={"detailsContent"}>
                                    <p className={"justified"}><Trans i18nKey="pastHighlights.y2019.content" components={[<b/>,<br/>]} /></p>
                                    {/*<Button value="/pasthighlights/2019/" function={(e) => handleLink(e.target.value)} className={"linkBtn"}>*/}
                                    {/*    <img src={whiteRightArrow} />*/}
                                    {/*</Button>*/}
                                </div>
                            </div>

                            <div className="Tilt">
                                <a className="Tilt-inner" target="_blank" href={`/pasthighlights/2019/${i18n.language}`}>
                                    <img src={img2019} />
                                </a>
                            </div>
                        </div>
                        <div className={"highlightWrap"}>
                            <div className={"detailsWrap"}>
                                <h2>2018</h2>
                                <div className={"detailsContent"}>
                                    <p className={"justified"}><Trans i18nKey="pastHighlights.y2018.content" components={[<b/>,<br/>]} /></p>
                                    {/*<Button value="/pasthighlights/2018/" function={(e) => handleLink(e.target.value)} className={"linkBtn"}>*/}
                                    {/*    <img src={whiteRightArrow} />*/}
                                    {/*</Button>*/}
                                </div>
                            </div>
                            
                            <div className="Tilt">
                                <a className="Tilt-inner" target="_blank" href={`/pasthighlights/2018/${i18n.language}`}>
                                    <img src={img2018} />
                                </a>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </MotionDiv>
    );
}

export default PastHighlight;
