const initState = {
    walkerRef:{}
};
const walkerRefReducer = (state = initState, action) => {

	switch(action.type) {
		case 'ADD_WALKERREF': {
			const { floorId, object } = action.payload
            return {
                ...state,
                walkerRef: {
                    ...state.walkerRef,
                    [floorId]: {
                        ref: object
                    }
                }
            }
		}

        case 'RESET_WALKERREF': {
            const { floorId } = action.payload
            return {
                ...state,
                walkerRef: {
                    ...state.walkerRef,
                    [floorId]: {
                        ref: ""
                    }
                }
            }
        }
        default: 
            return state;

    }
};

export {walkerRefReducer};