import {Trans} from "react-i18next";
import Button from "../Button";
import downWhiteArrow from "../../../assets/images/share/arrow_down_white.svg";
import {motion} from "framer-motion";
import React, {useEffect, useRef} from "react";
import useCheckDeviceMode from "../../hooks/useCheckDeviceMode";
import popupBg from "../../../assets/images/programme/bg_speaker_content.jpg"
import menuBg from "../../../assets/images/share/bg_menu.jpg";
import closeBtnImg from "../../../assets/images/share/btn_close.svg";
import SimpleBar from "simplebar-react";
import "simplebar/src/simplebar.css";

function SpeakerPopup (props) {
    const responsiveMode = useCheckDeviceMode();
    const containerRef = useRef(null)

    const speakerContentVariants = {
        initial: { opacity: 0, y: 100},
        animate: {
            opacity: 1,
            y: 0,
            backgroundImage: `url(${popupBg})`
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", props.hideSpeakerContent);

        return () => {
            window.removeEventListener("scroll", props.hideSpeakerContent);
        };
    }, []);

    return(
        <motion.div className={"speakerContent"}
                    initial={"initial"}
                    animate={"animate"}
                    exit={"initial"}
                    variants={speakerContentVariants}
                    transition={{ ease: "easeInOut", duration: 0.5, type: "spring" }}
        >

            <div className={"speakerContentWrap"}>
                {/*{responsiveMode!=="desktop-view"?*/}
                    <div className={"imgOuterWrap"}>
                        <div className="imgInnerWrap">
                            <img src={props.speakerImg} />
                        </div>
                    </div>
                {/*    :""*/}
                {/*}*/}


                <SimpleBar  className={"textWrap"} style={{ maxHeight: responsiveMode==="desktop-view"? 240:responsiveMode==="tablet-view"?"calc(100% - 174px)": "calc(100% - 144px)" }}>
                    {/*<div className={"textWrap"} >*/}
                        {/*{responsiveMode!=="desktop-view"?*/}
                            <>
                                <div dangerouslySetInnerHTML={{__html: props.speakerName,}}/>
                                <div dangerouslySetInnerHTML={{__html: props.speakerTitle,}}/>
                                <br/>
                            </>
                        {/*:""*/}
                        {/*}*/}
                        <p dangerouslySetInnerHTML={{__html: props.speakerContent,}}>
                            {/*<Trans i18nKey={props.speakerContent}></Trans>*/}
                        </p>

                    {/*</div>*/}
                </SimpleBar >
            </div>

            <Button function={(e) => props.setSpeakerContent(undefined)}><img src={closeBtnImg} /></Button>

        </motion.div>
    )
};

export default SpeakerPopup;
