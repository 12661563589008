
import MotionDiv from "../../components/UI/MotionDiv";
import { useTranslation, Trans } from "react-i18next";
import Button from "../../components/UI/Button";
import ProgrammeWrap from "../../components/UI/ProgrammeWrap";
import celebrateIcon from "../../assets/images/programme/icon_celebrate.svg";
import discussIcon from "../../assets/images/programme/icon_discuss.svg";
import endIcon from "../../assets/images/programme/icon_end.svg";
import pollIcon from "../../assets/images/programme/icon_poll.svg";
import regIcon from "../../assets/images/programme/icon_reg.svg";
import speechIcon from "../../assets/images/programme/icon_speech.svg";
import talkIcon from "../../assets/images/programme/icon_talk.svg";
import videoIcon from "../../assets/images/programme/icon_video.svg";
import speakerImg1 from "../../assets/images/programme/img_speaker.jpg";
import downWhiteArrow from "../../assets/images/share/arrow_down_white.svg";
import banner from "../../assets/images/programme/banner.png";
import bannerMob from "../../assets/images/programme/banner-mobile.png";
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import useCheckDeviceMode from "../../components/hooks/useCheckDeviceMode";

import "./index.scss";
import calBtnCss from "./calBtn.css";
import React, {useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import { motion, AnimatePresence} from "framer-motion";
import SpeakerPopup from "../../components/UI/SpeakerPopup";


function Programme() {
    const { t, i18n } = useTranslation();
    const [currentLng, setCurrentLng] = useState(i18n.language);
    // const swiper = useSwiper();
    const [morningActive, setMorningActive] = useState(true);
    const [speakerImg, setSpeakerImg] = useState();
    const [speakerName, setSpeakerName] = useState();
    const [speakerHonor, setSpeakerHonor] = useState();
    const [speakerTitle, setSpeakerTitle] = useState();
    const [speakerDepartment, setSpeakerDepartment] = useState();
    const [speakerContent, setSpeakerContent] = useState();
    const [speakerList, setSpeakerList] = useState([]);
    const history = useHistory();
    const [current, setCurrent] = useState(0);

    const responsiveMode = useCheckDeviceMode();

    const clickSpeaker = (e, img, name, title, content) => {
        // console.log(e)
        setCurrent(current+1)
        setSpeakerImg(img)
        setSpeakerName(name)
        setSpeakerTitle(title)
        setSpeakerContent(content)
    }

    function handleLink(e) {
        history.push(e);
    }


    const speakerContentVariants = {
        initial: { opacity: 0, y: 100},
        animate: {
            opacity: 1,
            y: 0,
        }
    };
    const programmeListVariants = {
        initial: { opacity: 0},
        animate: {
            opacity: 1,
        }
    };
    const programmeListVariants2 = {
        initial: { opacity: 0},
        animate: {
            opacity: 1,
        }
    };


    useEffect(()=>{
        if (currentLng){
            // console.log("currentLng", currentLng)

            const fetchSpeakers = async () => {
                fetch("/assets/data/speakers_"+currentLng+".json", {
                    headers : {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                })
                    .then(res => res.json())
                    .then((result) => {
                        // console.log("result.speakers", result.speakers)
                        setSpeakerList(result.speakers)

                    })
            };
            fetchSpeakers();

        }
    }, [currentLng])

    useEffect(()=>{
        setCurrentLng(i18n.language)
        hideSpeakerContent()
    }, [i18n.language])

    useEffect(()=>{
        // console.log("speakerList", speakerList)
        // console.log("Object.keys(speakerList).length", Object.keys(speakerList).length)

    }, [speakerList])

    const hideSpeakerContent = () => {
        setSpeakerContent(undefined)
    }

    useEffect(()=>{
        // const script = document.createElement("script");
        // script.src = "/static/libs/your_script.js";
        // script.async = true;
        //
        // document.body.appendChild(script);
        window.addEventListener("scroll", hideSpeakerContent);
        return () => {
            window.removeEventListener("scroll", hideSpeakerContent);
        };
    }, [])

    return (
        <MotionDiv>
            <div className={"insidePageBg"}>
                {/*<div className="bgImg"></div>*/}
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>

                <div className={"insidePageContent"}>


                    <div className={"contentWrap"}>
                        <div className={"programmePageSubtitle"}>
                            <span>{t("programme.subtitle1")}</span>
                            <br/>
                            <span className={"space"}></span><span>{t("programme.subtitle2")}</span>
                        </div>

                        {responsiveMode !== "mobile-view"?
                            <img src={banner} />
                            :
                            <img src={bannerMob} />
                        }
                        <p className={"bannerContent"}>{t("programme.bannerContent")}</p>
                        <p className={"bannerContent"}>{t("programme.bannerContent2")}</p>

                    </div>
                    <h1 className={"pageTitle programmePageTitle"}>{t("programme.title")}</h1>
                    <div className={"contentWrap"}>

                        <div className={"tabsWrap"}>
                            <div className={"sessionBtns"}>
                                <Button function={(e) => setMorningActive(true)} className={`sessionBtn ${morningActive? "active": ""}`}>
                                    {t("programme.morningSess")}
                                </Button>
                                <Button function={(e) => setMorningActive(false)} className={`sessionBtn ${morningActive? "": "active"}`}>
                                    {t("programme.afternoonSess")}
                                </Button>
                            </div>

                            {/*<Button className={"addToCal btnWithHover"}>*/}
                                {/*<a href="/"*/}
                                {/*   data-event="6321a0b7b22d8b0cc7559195" className="eventable-link" target="_blank"*/}
                                {/*   data-key="63219f94df728f18e0e0c5bb" data-style="1">{t("programme.addToCal")}</a>*/}

                                <AddToCalendarButton
                                    name="AIF 2023"
                                    startDate="2023-12-08"
                                    startTime="09:30"
                                    endTime="17:05"
                                    timeZone={"Asia/Hong_Kong"}
                                    styleLight="--btn-background: transparent; --btn-background-hover: transparent; --btn-border: none; --btn-text: #1CE9CE; --btn-text-hover: #1CE9CE; --font: 'Barlow', serif; --btn-shadow: none; --btn-shadow-hover: none; --btn-shadow-active: none; "
                                    options={['Apple','Google','Yahoo','iCal']}
                                    hideBackground={true}
                                    hideIconButton={true}
                                    label={t("programme.addToCal")}
                                    lightMode="bodyScheme"
                                    hideCheckmark={true}
                                    className={"addToCal btnWithHover"}
                                ></AddToCalendarButton>
                            {/*</Button>*/}
                        </div>

                        <AnimatePresence mode={"wait"}>

                            {morningActive && speakerList && Object.keys(speakerList).length > 0 && (
                                <motion.div className={"programmeList"}
                                            initial={"initial"}
                                            animate={"animate"}
                                            exit={"initial"}
                                            variants={programmeListVariants}
                                >

                                    <ProgrammeWrap
                                        type={"type2"}
                                        icon={talkIcon}
                                        time={t("programme.programmes.programme1.time")}
                                        title={t("programme.programmes.programme1.title")}
                                        subtitle={t("programme.programmes.programme1.subtitle")}
                                        details={true}
                                        speaker1Name={speakerList[1].nameWithHonor}
                                        speaker1PopupName={speakerList[1].name}
                                        speaker1Img={speakerList[1].img}
                                        speaker1Content={speakerList[1].content}
                                        speaker1Title={speakerList[1].titleDepartment}
                                        speaker1PopupTitle={speakerList[1].title}
                                        clickSpeaker={clickSpeaker}
                                    />

                                    {/*<ProgrammeWrap*/}
                                    {/*    type={"type2"}*/}
                                    {/*    icon={talkIcon}*/}
                                    {/*    time={t("programme.programmes.programme2.time")}*/}
                                    {/*    title={t("programme.programmes.programme2.title")}*/}
                                    {/*    details={false}*/}
                                    {/*    speaker1Name={t("programme.programmes.programme2.speakerName")}*/}
                                    {/*    speaker1Title={t("programme.programmes.programme2.speakerTitle")}*/}
                                    {/*/>*/}

                                    <ProgrammeWrap
                                        type={"type2"}
                                        icon={discussIcon}
                                        time={t("programme.programmes.programme3.time")}
                                        title={t("programme.programmes.programme3.title")}
                                        details={true}
                                        mod1Name={speakerList[3].nameWithHonor}
                                        mod1PopupName={speakerList[3].name}
                                        mod1Img={speakerList[3].img}
                                        mod1Content={speakerList[3].content}
                                        mod1Title={speakerList[3].titleDepartment}
                                        mod1PopupTitle={speakerList[3].title}
                                        speaker1Name={speakerList[5].nameWithHonor}
                                        speaker1PopupName={speakerList[5].name}
                                        speaker1Img={speakerList[5].img}
                                        speaker1Content={speakerList[5].content}
                                        speaker1Title={speakerList[5].titleDepartment}
                                        speaker1PopupTitle={speakerList[5].title}
                                        speaker2Name={speakerList[6].nameWithHonor}
                                        speaker2PopupName={speakerList[6].name}
                                        speaker2Img={speakerList[6].img}
                                        speaker2Content={speakerList[6].content}
                                        speaker2Title={speakerList[6].titleDepartment}
                                        speaker2PopupTitle={speakerList[6].title}
                                        speaker3Name={speakerList[7].nameWithHonor}
                                        speaker3PopupName={speakerList[7].name}
                                        speaker3Img={speakerList[7].img}
                                        speaker3Content={speakerList[7].content}
                                        speaker3Title={speakerList[7].titleDepartment}
                                        speaker3PopupTitle={speakerList[7].title}
                                        speaker4Name={speakerList[8].nameWithHonor}
                                        speaker4PopupName={speakerList[8].name}
                                        speaker4Img={speakerList[8].img}
                                        speaker4Content={speakerList[8].content}
                                        speaker4Title={speakerList[8].titleDepartment}
                                        speaker4PopupTitle={speakerList[8].title}
                                        clickSpeaker={clickSpeaker}
                                        video={"/"}
                                        speech={"/"}
                                    />

                                    <ProgrammeWrap
                                        type={"type2"}
                                        icon={talkIcon}
                                        time={t("programme.programmes.programme4.time")}
                                        title={t("programme.programmes.programme4.title")}
                                        details={true}
                                        speaker1Name={speakerList[9].nameWithHonor}
                                        speaker1PopupName={speakerList[9].name}
                                        speaker1Img={speakerList[9].img}
                                        speaker1Content={speakerList[9].content}
                                        speaker1Title={speakerList[9].titleDepartment}
                                        speaker1PopupTitle={speakerList[9].title}
                                        clickSpeaker={clickSpeaker}
                                    />

                                    <ProgrammeWrap
                                        type={"type1"}
                                        icon={celebrateIcon}
                                        time={t("programme.programmes.programme5.time")}
                                        title={t("programme.programmes.programme5.title")}
                                        details={false}
                                    />

                                    <ProgrammeWrap
                                        type={"type3"}
                                        icon={discussIcon}
                                        time={t("programme.programmes.programme6.time")}
                                        title={t("programme.programmes.programme6.title")}
                                        details={true}
                                        mod1Name={speakerList[10].nameWithHonor}
                                        mod1PopupName={speakerList[10].name}
                                        mod1Img={speakerList[10].img}
                                        mod1Content={speakerList[10].content}
                                        mod1Title={speakerList[10].titleDepartment}
                                        mod1PopupTitle={speakerList[10].title}
                                        speaker1Name={speakerList[11].nameWithHonor}
                                        speaker1PopupName={speakerList[11].name}
                                        speaker1Img={speakerList[11].img}
                                        speaker1Content={speakerList[11].content}
                                        speaker1Title={speakerList[11].titleDepartment}
                                        speaker1PopupTitle={speakerList[11].title}
                                        speaker2Name={speakerList[12].nameWithHonor}
                                        speaker2PopupName={speakerList[12].name}
                                        speaker2Img={speakerList[12].img}
                                        speaker2Content={speakerList[12].content}
                                        speaker2Title={speakerList[12].titleDepartment}
                                        speaker2PopupTitle={speakerList[12].title}
                                        speaker3Name={speakerList[13].nameWithHonor}
                                        speaker3PopupName={speakerList[13].name}
                                        speaker3Img={speakerList[13].img}
                                        speaker3Content={speakerList[13].content}
                                        speaker3Title={speakerList[13].titleDepartment}
                                        speaker3PopupTitle={speakerList[13].title}
                                        speaker4Name={speakerList[14].nameWithHonor}
                                        speaker4PopupName={speakerList[14].name}
                                        speaker4Img={speakerList[14].img}
                                        speaker4Content={speakerList[14].content}
                                        speaker4Title={speakerList[14].titleDepartment}
                                        speaker4PopupTitle={speakerList[14].title}
                                        clickSpeaker={clickSpeaker}
                                        video={"/"}
                                        poll={"/"}
                                    />
                                    <ProgrammeWrap
                                        type={"type1"}
                                        icon={endIcon}
                                        time={t("programme.programmes.programme7.time")}
                                        title={t("programme.programmes.programme7.title")}
                                        details={false}
                                    />
                                </motion.div>
                            )}
                        </AnimatePresence>

                        <AnimatePresence mode={"wait"}>
                            {!morningActive && speakerList && Object.keys(speakerList).length > 0 && (
                                <motion.div className={"programmeList"}
                                            initial={"initial"}
                                            animate={"animate"}
                                            exit={"initial"}
                                            variants={programmeListVariants}
                                >

                                    <ProgrammeWrap
                                        type={"type2"}
                                        icon={talkIcon}
                                        time={t("programme.programmes.programme8.time")}
                                        title={t("programme.programmes.programme8.title")}
                                        details={true}
                                        // mod1Name={speakerList[12].nameWithHonor}
                                        // mod1PopupName={speakerList[12].name}
                                        // mod1Img={speakerList[12].img}
                                        // mod1Content={speakerList[12].content}
                                        // mod1Title={speakerList[12].titleDepartment}
                                        // mod1PopupTitle={speakerList[12].title}
                                        speaker1Name={speakerList[15].nameWithHonor}
                                        speaker1PopupName={speakerList[15].name}
                                        speaker1Img={speakerList[15].img}
                                        speaker1Content={speakerList[15].content}
                                        speaker1Title={speakerList[15].titleDepartment}
                                        speaker1PopupTitle={speakerList[15].title}
                                        clickSpeaker={clickSpeaker}
                                        video={"/"}
                                        speech={"/"}
                                    />

                                    <ProgrammeWrap
                                        type={"type3"}
                                        icon={talkIcon}
                                        time={t("programme.programmes.programme9.time")}
                                        title={t("programme.programmes.programme9.title")}
                                        details={true}
                                        speaker1Name={speakerList[16].nameWithHonor}
                                        speaker1PopupName={speakerList[16].name}
                                        speaker1Img={speakerList[16].img}
                                        speaker1Content={speakerList[16].content}
                                        speaker1Title={speakerList[16].titleDepartment}
                                        speaker1PopupTitle={speakerList[16].title}
                                        clickSpeaker={clickSpeaker}
                                        video={"/"}
                                        poll={"/"}
                                    />
                                    <ProgrammeWrap
                                        type={"type2"}
                                        icon={discussIcon}
                                        time={t("programme.programmes.programme10.time")}
                                        title={t("programme.programmes.programme10.title")}
                                        details={true}
                                        mod1Name={speakerList[17].nameWithHonor}
                                        mod1PopupName={speakerList[17].name}
                                        mod1Img={speakerList[17].img}
                                        mod1Content={speakerList[17].content}
                                        mod1Title={speakerList[17].titleDepartment}
                                        mod1PopupTitle={speakerList[17].title}
                                        speaker1Name={speakerList[18].nameWithHonor}
                                        speaker1PopupName={speakerList[18].name}
                                        speaker1Img={speakerList[18].img}
                                        speaker1Content={speakerList[18].content}
                                        speaker1Title={speakerList[18].titleDepartment}
                                        speaker1PopupTitle={speakerList[18].title}
                                        speaker2Name={speakerList[19].nameWithHonor}
                                        speaker2PopupName={speakerList[19].name}
                                        speaker2Img={speakerList[19].img}
                                        speaker2Content={speakerList[19].content}
                                        speaker2Title={speakerList[19].titleDepartment}
                                        speaker2PopupTitle={speakerList[19].title}
                                        speaker3Name={speakerList[20].nameWithHonor}
                                        speaker3PopupName={speakerList[20].name}
                                        speaker3Img={speakerList[20].img}
                                        speaker3Content={speakerList[20].content}
                                        speaker3Title={speakerList[20].titleDepartment}
                                        speaker3PopupTitle={speakerList[20].title}
                                        speaker4Name={speakerList[21].nameWithHonor}
                                        speaker4PopupName={speakerList[21].name}
                                        speaker4Img={speakerList[21].img}
                                        speaker4Content={speakerList[21].content}
                                        speaker4Title={speakerList[21].titleDepartment}
                                        speaker4PopupTitle={speakerList[21].title}
                                        clickSpeaker={clickSpeaker}
                                        coor={true}
                                        video={"/"}
                                        speech={"/"}
                                    />
                                    <ProgrammeWrap
                                        type={"type3"}
                                        icon={discussIcon}
                                        time={t("programme.programmes.programme11.time")}
                                        title={t("programme.programmes.programme11.title")}
                                        details={true}
                                        mod1Name={speakerList[23].nameWithHonor}
                                        mod1PopupName={speakerList[23].name}
                                        mod1Img={speakerList[23].img}
                                        mod1Content={speakerList[23].content}
                                        mod1Title={speakerList[23].titleDepartment}
                                        mod1PopupTitle={speakerList[23].title}
                                        speaker1Name={speakerList[24].nameWithHonor}
                                        speaker1PopupName={speakerList[24].name}
                                        speaker1Img={speakerList[24].img}
                                        speaker1Content={speakerList[24].content}
                                        speaker1Title={speakerList[24].titleDepartment}
                                        speaker1PopupTitle={speakerList[24].title}
                                        speaker2Name={speakerList[25].nameWithHonor}
                                        speaker2PopupName={speakerList[25].name}
                                        speaker2Img={speakerList[25].img}
                                        speaker2Content={speakerList[25].content}
                                        speaker2Title={speakerList[25].titleDepartment}
                                        speaker2PopupTitle={speakerList[25].title}
                                        speaker3Name={speakerList[26].nameWithHonor}
                                        speaker3PopupName={speakerList[26].name}
                                        speaker3Img={speakerList[26].img}
                                        speaker3Content={speakerList[26].content}
                                        speaker3Title={speakerList[26].titleDepartment}
                                        speaker3PopupTitle={speakerList[26].title}
                                        speaker4Name={speakerList[27].nameWithHonor}
                                        speaker4PopupName={speakerList[27].name}
                                        speaker4Img={speakerList[27].img}
                                        speaker4Content={speakerList[27].content}
                                        speaker4Title={speakerList[27].titleDepartment}
                                        speaker4PopupTitle={speakerList[27].title}
                                        clickSpeaker={clickSpeaker}
                                        video={"/"}
                                        poll={"/"}
                                    />
                                    <ProgrammeWrap
                                        type={"type2"}
                                        icon={talkIcon}
                                        time={t("programme.programmes.programme12.time")}
                                        title={t("programme.programmes.programme12.title")}
                                        details={true}
                                        speaker1Name={speakerList[1].nameWithHonor}
                                        speaker1PopupName={speakerList[1].name}
                                        speaker1Img={speakerList[1].img}
                                        speaker1Content={speakerList[1].content}
                                        speaker1Title={speakerList[1].titleDepartment}
                                        speaker1PopupTitle={speakerList[1].title}
                                        speaker2Name={speakerList[28].nameWithHonor}
                                        speaker2PopupName={speakerList[28].name}
                                        speaker2Img={speakerList[28].img}
                                        speaker2Content={speakerList[28].content}
                                        speaker2Title={speakerList[28].titleDepartment}
                                        speaker2PopupTitle={speakerList[28].title}
                                        clickSpeaker={clickSpeaker}
                                        video={"/"}
                                        speech={"/"}
                                    />
                                    <ProgrammeWrap
                                        type={"type2"}
                                        icon={endIcon}
                                        time={t("programme.programmes.programme13.time")}
                                        title={t("programme.programmes.programme13.title")}
                                        details={false}
                                        video={"/"}
                                        speech={"/"}
                                    />
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>

                    <AnimatePresence mode={"wait"} exitBeforeEnter initial={false}>
                        {speakerContent && (
                            <SpeakerPopup speakerImg={speakerImg} speakerName={speakerName} speakerTitle={speakerTitle} speakerContent={speakerContent} setSpeakerContent={setSpeakerContent}  hideSpeakerContent={hideSpeakerContent} key={current}/>
                        )}
                    </AnimatePresence>

                </div>
            </div>
        </MotionDiv>
    );
}

export default Programme;
