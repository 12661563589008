import React, {Suspense, useRef} from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./views/App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store.js";
import "./index.scss";
import Loading from "./views/Loading";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <Suspense fallback={<Loading />} >
          <Router basename={""}>
                <App />
          </Router>
        </Suspense>
    </Provider>
  </React.StrictMode>,
  document.getElementById("App")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
