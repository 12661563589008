const initState = {
    routeInstructions:[]
};
const routeInstructionsReducer = (state = initState, action) => {

	switch(action.type) {
		case 'ADD_INSTRUCTIONS': {
			const { object } = action.payload
            return {
            // state = {
                ...state,
                routeInstructions: [
                    ...state.routeInstructions,
                    object
                ]
            }
		}
		case 'RESET_INSTRUCTIONS': {
			return {
				// state = {
					...state,
					routeInstructions: []
				}
			// }
		}

        default: 
            return state;

	}
};

export {routeInstructionsReducer};