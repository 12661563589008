import React, {useEffect, useRef} from "react";
import {Switch, Route, NavLink, useLocation, useParams, useHistory} from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Layout from "../components/Layout";
import ScrollToTop from "../components/UI/ScrollToTop";
import Home from "./Home";
import Programme from "./Programme";
import Speakers from "./Speakers"
import Registration from "./Registration";
import InformationCenter from "./InformationCenter";
import PastHighlight from "./PastHighlight";

import { Suspense } from "react";
import {I18nextProvider, useTranslation} from "react-i18next";
import i18next from "i18next";

import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Accessibility from "./Accessibility";
// import {SmoothScrollbarReact} from 'smooth-scrollbar-react';
import { FrameProvider } from 'framed-scroll-motion';
import ReactGA from 'react-ga4';
import {Helmet} from "react-helmet";


function App(props) {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    // const { lang } = useParams();
    const history = useHistory()
    const baseUrl = '/'+i18next.language;
    // const lng = i18n.language;
    const lng = i18next.language;

    // const DescriptionMeta = () => {
    //
    //     return (
    //         <Helmet>
    //             <meta name="description" content={"meta description"} />
    //         </Helmet>
    //     );
    // };
    useEffect(()=>{

        if (window) {
            // const routes = i18next.getResourceBundle(i18next.language, 'routes');
            const currentPathname = window.location.pathname.replace(/\/+$/, '');
            let lang = currentPathname.includes("/sc")? "sc": currentPathname.includes("/tc")? "tc": "en";

            // const newPathName = currentPathname.replace(i18n.language, nextLanguage)
            localStorage.setItem("i18nextLng", lang);
            i18next.changeLanguage(lang);

            // window.history.replaceState(null, "", window.location.origin+newPathName)

            // if (localStorage.getItem('i18nextLng')){
            //     let storedLang = localStorage.getItem('i18nextLng')
            //     console.log("storedLang", storedLang)
            //
            //     document.documentElement.setAttribute('lang', storedLang)
            //     if (storedLang!==i18n.language){
            //         const currentPathname = window.location.pathname.replace(/\/+$/, '');
            //         const newPathName = currentPathname.replace(i18n.language, localStorage.getItem('i18nextLng'))
            //         // window.location.replace(window.location.origin+newPathName);
            //         i18n.changeLanguage(localStorage.getItem('i18nextLng'));
            //
            //     }
            // }
        }

        i18next.on('languageChanged', (lng) => {document.documentElement.setAttribute('lang', lng);})

        // ReactGA.initialize('G-XEK4C1Z5KS', {
        //     'cookieDomain': 'auto',
        //     'debug': false
        // });
        // ReactGA.pageview(window.location.pathname + window.location.search)

    }, [])


    useEffect(()=> {

        ReactGA.initialize([
            // {
            //     trackingId: "UA-128865909-1",
            // },
            {
                trackingId: "G-XEK4C1Z5KS",
            }
        ]);

        // console.log("window.location.pathname + window.location.search", window.location.pathname + window.location.search)

        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

        // ga4react.initialize().then((ga4) => {
        //     ga4.pageview(window.location.pathname + window.location.search)
        //     // ga4.gtag('event','pageview','path') // or your custom gtag event
        // },(err) => {
        //     console.error(err)
        // })
    }, [location])

    // add lang to url if it's not present
    function langRedirect(props) {
        // console.log({ props });
        const defaultLang = i18next.language;
        // console.log("defaultLang", defaultLang);
        const redirectPath = `/${defaultLang}`;
        // console.log(redirectPath);
        props.history.replace({
            pathname: redirectPath,
        });
        document.documentElement.lang = i18n.language
        window.location.reload();

    }

    return (
        // <Suspense fallback={<div>Loading</div>}>
            <I18nextProvider i18n={i18next}>
                <Layout>
                    <AnimatePresence exitBeforeEnter>
                            <ScrollToTop />

                            <Switch location={location} key={location.pathname}>
                                {/*<Route path="/" component={App}>*/}
                                {/*    <Route path=":lang">*/}
                                {/*        <Route exact path={`/`} component={Home} />*/}
                                {/*        <Route exact path={`/programme`} component={Programme} />*/}
                                {/*        <Route exact path={`/speakers`} component={Speakers} />*/}
                                {/*        <Route exact path={`/registration`} component={Registration} />*/}
                                {/*        <Route exact path={`/information-center`} component={InformationCenter} />*/}
                                {/*        <Route exact path={`/past-highlight`} component={PastHighlight} />*/}
                                {/*        <Route exact path={`/accessibility`} component={Accessibility} />*/}
                                {/*        <Route path="*" render={langRedirect} />*/}
                                {/*    </Route>*/}

                                {/*</Route>*/}

                                {/*<Route path={`/${lang}`} >*/}
                                {/*<Route  path="/" element={<NavLink to={"/:lng/"} /> } exact />*/}
                                {/*<Route exact path={`/`+lng+`/`} component={Home} />*/}
                                {/*<Route exact path={`/`+lng+`/programme`} component={Programme} />*/}
                                {/*<Route exact path={`/`+lng+"/speakers"} component={Speakers} />*/}
                                {/*<Route exact path={`/`+lng+"/registration"} component={Registration} />*/}
                                {/*<Route exact path={`/`+lng+"/information-center"} component={InformationCenter} />*/}
                                {/*<Route exact path={`/`+lng+"/past-highlight"} component={PastHighlight} />*/}
                                {/*<Route exact path={`/`+lng+"/accessibility"} component={Accessibility} />*/}
                                <Route exact path={`/:lng/`} component={Home} />
                                <Route exact path={`/:lng/programme`} component={Programme} />
                                <Route exact path={`/:lng/speakers`} component={Speakers} />
                                {/*<Route exact path={`/:lng/registration`} component={Registration} />*/}
                                <Route exact path={`/:lng/information-center`} component={InformationCenter} />
                                <Route exact path={`/:lng/past-highlight`} component={PastHighlight} />
                                <Route exact path={`/:lng/accessibility`} component={Accessibility} />
                                <Route path="*" render={langRedirect} />
                                {/*<Rou te exact path={"/"} component={Home} />*/}
                                {/*<Route exact path={"/programme"} component={Programme} />*/}
                                {/*<Route exact path={"/speakers"} component={Speakers} />*/}
                                {/*<Route exact path={"/registration"} component={Registration} />*/}
                                {/*<Route exact path={"/information-center"} component={InformationCenter} />*/}
                                {/*<Route exact path={"/past-highlight"} component={PastHighlight} />*/}
                                {/*<Route exact path={"/accessibility"} component={Accessibility} />*/}
                                {/*<Route path={`${process.env.PUBLIC_URL}/`} component={Home} />*/}
                                {/*<Route path={`${process.env.PUBLIC_URL}/programme`} component={Programme} />*/}
                                {/*<Route path={`${process.env.PUBLIC_URL}/speakers`} component={Speakers} />*/}
                                {/*<Route path={`${process.env.PUBLIC_URL}/registration`} component={Registration} />*/}
                                {/*<Route path={`${process.env.PUBLIC_URL}/information-center`} component={InformationCenter} />*/}
                                {/*<Route path={`${process.env.PUBLIC_URL}/past-highlight`} component={PastHighlight} />*/}
                                {/*</Route>*/}
                                </Switch>
                        </AnimatePresence>
                    </Layout>

            </I18nextProvider>
        // </Suspense>
    );
}

export default App;
