const initState = {
    mapRef:{}
};
const mapRefReducer = (state = initState, action) => {

	switch(action.type) {
		case 'ADD_MAPREF': {
			const { floorId, object } = action.payload
            state = {
                ...state,
                mapRef: {
                    ...state.mapRef,
                    [floorId]: object
                }
            }
		}
        default: 
            return state;

    }
};

export {mapRefReducer};