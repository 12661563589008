import { compose, createStore, combineReducers, applyMiddleware } from "redux";
import { layoutReducer } from "./reducers/layoutReducer";
import { cyListReducer } from "./reducers/cyListReducer";
import { shopInfoReducer } from "./reducers/shopReducer";
import { highlightListReducer } from "./reducers/highlightListReducer";
import { cyRefReducer } from "./reducers/cyRefReducer";
import { polylineRefReducer } from "./reducers/polylineRefReducer";
import { walkDurationListReducer } from "./reducers/walkDurationListReducer";
import { facilityListByFloorReducer } from "./reducers/facilityListByFloorReducer";
import { walkerRefReducer } from "./reducers/walkerRefReducer";
import { walkerXReducer } from "./reducers/walkerXReducer";
import { mapRefReducer } from "./reducers/mapRefReducer";
import { settingReducer } from "./reducers/settingReducer";
import { routeInstructionsReducer } from "./reducers/routeInstructionsReducer";
import { lineXYStrReducer } from "./reducers/lineXYStrReducer";
import { walkingPathReducer } from "./reducers/walkingPathReducer";
// import { cyListReducer } from "./reducers/reducer";
import logger from "redux-logger";
import thunk from "redux-thunk";


// const myLogger = (store) => (next) => (action) => {
//   console.log("store state", store.getState());
//   console.log("logged action: ",  action);
//   next(action);
// }

const store = createStore(
  combineReducers({cyListReducer, shopInfoReducer, highlightListReducer, cyRefReducer, polylineRefReducer, walkDurationListReducer, layoutReducer, facilityListByFloorReducer, walkerRefReducer, mapRefReducer, settingReducer, routeInstructionsReducer, lineXYStrReducer, walkingPathReducer, walkerXReducer}),
  {},
  // applyMiddleware(logger)
  
)
// const store = createStore(
//   combineReducers({cyListReducer},
//     {},
//     applyMiddleware(myLogger, logger())
//   )
// );

// store.subscribe(()=>{
//   console.log("store state", store.getState());
// })


export default store;
