import Header from "./Header";
import Footer from "./Footer";
import Parallax from "../UI/Parallax";
import "./index.scss";
import {Trans, useTranslation} from "react-i18next";
import {
    useViewportScroll,
    useTransform,
    useSpring,
    motion, useScroll
} from "framer-motion";
import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import { FrameProvider } from 'framed-scroll-motion';
import useCheckDeviceMode from "../../components/hooks/useCheckDeviceMode";
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Layout(props) {
    const { t, i18n } = useTranslation();
    const currentLng = i18n.language;
    const mainRef = useRef();
    const footerRef = useRef();
    const [mainOffsetTop, setMainOffsetTop] = useState(0);
    const [mainMinHeight, setMainMinHeight] = useState("auto");
    const [footerOffsetTop, setFooterOffsetTop] = useState(0);
    const [footerOffsetHeight, setFooterOffsetHeight] = useState("auto");
    const { scrollY } = useViewportScroll();
    const [appHeight, setAppHeight] = useState(0);
    const [currentScroll, setCurrentScroll] = useState(0);
    const responsiveMode = useCheckDeviceMode();

    const range = 0.2;
    const rand = (min = 0, max = 100) => {
        return Math.floor(Math.random() * (+max - +min)) + +min;
    };
    const springConfig = {
        damping: 100,
        stiffness: 100,
        mass: rand(1, 3)
    };


    const calculateMinHeight = (height, range) => {
        return height + height * range;
    };


    useLayoutEffect(() => {

        if(appHeight){
            // start observing a DOM node
            resizeObserver.observe(document.getElementById("App"))
        }

        if (!footerRef.current) return null;

        // console.log("footer", footerRef.current)
        // const onResize = () => {
        //
        //     setFooterOffsetTop(footerRef.current.offsetTop);
        //     setFooterOffsetHeight(footerRef.current.offsetHeight);
        // };
        //
        // onResize();
        // window.addEventListener("resize", onResize);
        //
        // return () => window.removeEventListener("resize", onResize);
    }, [footerRef, appHeight]);


    const resizeObserver = new ResizeObserver(entries => {
        if (appHeight !== entries[0].target.clientHeight){
            setAppHeight(entries[0].target.clientHeight)
        }
    })


    const listenToScroll = () => {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight

        const scrolled = winScroll
        // const scrolled = winScroll / height
        setCurrentScroll(scrolled)

    }

    useEffect(()=>{
        setAppHeight(document.getElementById("App").getBoundingClientRect().height)

        window.addEventListener("scroll", listenToScroll);

        return () => window.removeEventListener("scroll", listenToScroll);
    }, [])




    const y1 = useTransform(scrollY, [0, 500], ["0", "-100%"]);
    const yForDes = useTransform(scrollY, [footerOffsetTop-document.documentElement.clientHeight, footerOffsetTop-(document.documentElement.clientHeight - footerOffsetHeight)], [-380, 0], { clamp: true });
    const yForMob = useTransform(scrollY, [footerOffsetTop-document.documentElement.clientHeight, footerOffsetTop-(document.documentElement.clientHeight - footerOffsetHeight)], [-150, 0],{ clamp: true });


    const DescriptionMeta = () => {
        return (
            <HelmetProvider>
                <Helmet>
                    <title>{t("meta.title")}</title>
                    <meta name="description" content={t("meta.desc")} />
                </Helmet>
            </HelmetProvider>
        );
    };


    return (
        <>
            {/*<div className={"emergency"}>*/}
            {/*    <p><i>{t("header.errorMsg")}</i> <u><a href={"https://iframe.dacast.com/live/5c6f0ab4-4712-9e39-72e3-3ccf84d9c6c9/d5ae0b96-c64d-0dd4-a415-5c43306da426"} target={"_blank"} >{t("header.eng")}</a></u> / <u><a href={"https://iframe.dacast.com/live/5c6f0ab4-4712-9e39-72e3-3ccf84d9c6c9/d547e101-2ae5-f7db-7728-623a301e237c"} target={"_blank"} >{t("header.puto")}</a></u></p>*/}
            {/*    /!*<p>{t("header.eng")}</p>*!/*/}
            {/*    /!*<a href={"https://iframe.dacast.com/live/5c6f0ab4-4712-9e39-72e3-3ccf84d9c6c9/d5ae0b96-c64d-0dd4-a415-5c43306da426"} target={"_blank"}>https://iframe.dacast.com/live/5c6f0ab4-4712-9e39-72e3-3ccf84d9c6c9/d5ae0b96-c64d-0dd4-a415-5c43306da426</a>*!/*/}
            {/*    /!*<p>{t("header.puto")}:</p>*!/*/}
            {/*    /!*<a href={"https://iframe.dacast.com/live/5c6f0ab4-4712-9e39-72e3-3ccf84d9c6c9/d547e101-2ae5-f7db-7728-623a301e237c"} target={"_blank"}>https://iframe.dacast.com/live/5c6f0ab4-4712-9e39-72e3-3ccf84d9c6c9/d547e101-2ae5-f7db-7728-623a301e237c</a>*!/*/}
            {/*    <p><i><Trans i18nKey={t("header.techSupp")} components={<a href={"mailto:aif@ia.org.hk"} />}></Trans></i></p>*/}
            {/*</div>*/}
            <div className="appWrap" >

                <DescriptionMeta />

                <Header />

                <main data-lang={currentLng}>
                    {props.children}
                </main>
                {/*<main>{props.children}</main>*/}
                <motion.footer ref={footerRef}>
                    <Footer />
                </motion.footer>
            </div>
        </>

    );
}

export default Layout;
