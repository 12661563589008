
import MotionDiv from "../../components/UI/MotionDiv";
import Parallax from "../../components/UI/Parallax";
import { useTranslation, Trans } from "react-i18next";
import Button from "../../components/UI/Button";
import speaker1Img from "../../assets/images/speakers/img_speaker1.png";
import speaker2Img from "../../assets/images/speakers/img_speaker2.png";
import speaker3Img from "../../assets/images/speakers/img_speaker3.png";
import downWhiteArrow from "../../assets/images/share/arrow_down_white.svg";
import openBtn from "../../assets/images/share/btn_open.svg";

import "./index.scss";
import React, {useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import { Frame, motion,  useAnimation, Override, Data, AnimatePresence} from "framer-motion";
function Accessibility() {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [currentLng, setCurrentLng] = useState(i18n.language);
    // const swiper = useSwiper();
    const [morningActive, setMorningActive] = useState(true);
    const [speakerContent, setSpeakerContent] = useState();
    const [speakerList, setSpeakerList] = useState([]);



    const clickSpeaker = (e, content) => {
        setSpeakerContent(content)
    }

    function handleLink(e) {
        history.push(e);
    }


    const speakerContentVariants = {
        initial: { opacity: 0, y: 100},
        animate: {
            opacity: 1,
            y: 0,
        }
    };
    const programmeListVariants = {
        initial: { opacity: 0},
        animate: {
            opacity: 1,
        }
    };
    const programmeListVariants2 = {
        initial: { opacity: 0},
        animate: {
            opacity: 1,
        }
    };

    useEffect(()=>{
        if (currentLng){

            const fetchSpeakers = async () => {
                fetch("/assets/data/speakers_"+currentLng+".json", {
                    headers : {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                })
                    .then(res => res.json())
                    .then((result) => {
                        setSpeakerList(Object.values(result.speakers))

                    })
            };
            fetchSpeakers();

        }
    }, [currentLng])

    useEffect(()=>{

        if (speakerList&&speakerList.length>0){

        }
    }, [speakerList])


    useEffect(()=>{
        if (t){
            setCurrentLng(i18n.language)
        }
    }, [t])


    return (
        <MotionDiv>
            <div className={"insidePageBg"}>
                {/*<div className="bgImg"></div>*/}
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>
                <div className="firefly"></div>

                <div className={"insidePageContent"}>
                    <h1 className={"pageTitle"}>{t("accessibility.title")}</h1>

                    <div className={"contentWrap accessibilityContentWrap"}>
                        <p>{t("accessibility.subtitle")}</p>

                        <h2>{t("accessibility.shortcut.title")}</h2>
                        <p>{t("accessibility.shortcut.content")}</p>
                        <p>{t("accessibility.shortcut.content2")}</p>

                        <h2>{t("accessibility.menu.title")}</h2>
                        <p>{t("accessibility.menu.content")}</p>

                        <u>{t("accessibility.menu.chrome.title")}</u>
                        <p>{t("accessibility.menu.chrome.content")}</p>

                        <u>{t("accessibility.menu.firefox.title")}</u>
                        <p>{t("accessibility.menu.firefox.content")}</p>
                        <p>{t("accessibility.menu.firefox.content2")}</p>

                        <u>{t("accessibility.menu.edge.title")}</u>
                        <p>{t("accessibility.menu.edge.content")}</p>
                        {/*<p>{t("accessibility.menu.edge.content2")}</p>*/}

                        {/*<u>{t("accessibility.menu.opera.title")}</u>*/}
                        {/*<p>{t("accessibility.menu.opera.content")}</p>*/}

                        <u>{t("accessibility.menu.safari.title")}</u>
                        <p>{t("accessibility.menu.safari.content")}</p>


                        <i>{t("accessibility.menu.disclaimer.title")}</i>
                        <i>{t("accessibility.menu.disclaimer.content")}</i>


                    </div>


                </div>
            </div>
        </MotionDiv>
    );
}

export default Accessibility;
