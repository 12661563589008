const shopInfoReducer = (
  state = {
    fullShopList: {},
    shoppingCatList: {},
    shoppingShopList: {},
    diningShopList: {},
    diningCatList: {}
  },
  action
) => {
  switch (action.type) {
    case "CREATE_FULL_SHOP_LIST": {
      const fullShopInfo = action.payload;
      return {
        ...state,
        fullShopList: fullShopInfo,
      };
    }
    case "CREATE_DINING_CAT_LIST": {
      const diningCatInfo = action.payload;
      return {
        ...state,
        diningCatList: diningCatInfo,
      };
    }
    case "CREATE_DINING_SHOP_LIST": {
      const diningShopInfo = action.payload;
      return {
        ...state,
        diningShopList: diningShopInfo,
      };
    }
    case "CREATE_SHOPPING_CAT_LIST": {
      const shoppingCatInfo = action.payload;
      return {
        ...state,
        shoppingCatList: shoppingCatInfo,
      };
    }
    case "CREATE_SHOPPING_SHOP_LIST": {
      const shoppingShopInfo = action.payload;
      return {
        ...state,
        shoppingShopList: shoppingShopInfo,
      };
    }
    default:
      return state;
  }
};

export { shopInfoReducer };
