import MotionDiv from "../../UI/MotionDiv";
import {Link, useHistory, useLocation} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";
import Languageswitcher from "../Header/Languageswitcher";
import {AnimatePresence, useTransform, useViewportScroll, motion} from "framer-motion";
import {  useDispatch } from "react-redux";
import Button from "../../UI/Button";
import React, {useLayoutEffect, useRef, useState, useEffect} from "react";
import useRefScrollProgress from "../../hooks/useRefScrollProgress"

function Footer() {

  const { t, i18n } = useTranslation();
  const baseUrl = '/'+i18n.language;
  const location = useLocation();
  const history = useHistory();

  const mainRef = useRef();
  const footerRef = useRef();
  const [mainOffsetTop, setMainOffsetTop] = useState(0);
  const [mainMinHeight, setMainMinHeight] = useState("auto");
  const [footerOffsetTop, setFooterOffsetTop] = useState(0);
  const [footerMinHeight, setFooterMinHeight] = useState("auto");
  const [footerHeight, setFooterHeight] = useState(700);
  const [appHeight, setAppHeight] = useState(0);


  // Prev Location Path Redux Globe State 
  const dispatch = useDispatch();
  const createPrevLocation = (pathname) => {
    dispatch({
      type: "CREATE_PREV_LOCATION",
      payload: pathname,
    });
  };
  
  const toContact = () => {
    history.goBack();
    // Set Prev Location Path 
    createPrevLocation(location.pathname);
  };

  const toRegistration = () => {
    history.push("/");
    // Set "Home" as Prev Location Path 
    createPrevLocation("/");
  };


  const { scrollY } = useViewportScroll();
  const range = 0.2;
  const rand = (min = 0, max = 100) => {
    return Math.floor(Math.random() * (+max - +min)) + +min;
  };
  const springConfig = {
    damping: 100,
    stiffness: 100,
    mass: rand(1, 3)
  };

  const calculateMinHeight = (height, range) => {
    return height + height * range;
  };

  useLayoutEffect(() => {
    if (!footerRef.current) return null;

    const onResize = () => {
        setFooterOffsetTop(footerRef.current.offsetTop);
        setFooterMinHeight(calculateMinHeight(footerRef.current.offsetHeight, range));
    };

    onResize();
    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  }, [footerRef]);

  // const y2 = useTransform(scrollY, [footerOffsetTop-100, footerOffsetTop], [100, 0]);

  const resizeObserver = new ResizeObserver(entries => {
    if (appHeight !== entries[0].target.clientHeight){
      setAppHeight(entries[0].target.clientHeight)
    }
  })

  useEffect(()=>{
    setAppHeight(document.getElementById("App").getBoundingClientRect().height)
  }, [])

  useEffect(()=>{
    if(appHeight){
      // start observing a DOM node
      resizeObserver.observe(document.getElementById("App"))
    }
  }, [appHeight])


  return (
    <AnimatePresence exitBeforeEnter>
      
        {/*<MotionDiv>*/}
          <div  ref={footerRef} >

            <motion.div>
            {/*<motion.div ref={footerRef} style={{ y: y2 }}>*/}
              <div className={"mainFooterWrap"}>
                <div className={"leftPanelWrap"}>
                  <h2><Trans i18nKey="home.banner.title"></Trans></h2>
                  <div>
                    <span>{t("footer.date")}</span>
                    <span>{t("footer.time")}</span>
                  </div>
                </div>
                <div className={"rightPanelWrap"}>
                  <h4>{t("footer.needHelp")}</h4>
                  {/*<Link to={baseUrl + `/registration`}>{t("footer.registration")}</Link>*/}
                  <a href={"mailto:aif@ia.org.hk"}>{t("footer.contact")}</a>
                </div>
              </div>
              <div className={"footerCopyrightWrap contentWrap"}>
                <p>{t("footer.copyright")}</p>
              </div>
            </motion.div>
          </div>
          
        {/*</MotionDiv>*/}
     
      </AnimatePresence>
  );
}

export default Footer;
